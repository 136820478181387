import { FolderState } from '@he-novation/front-shared/types/folder.front-types';
import { find, pick, pipe, property } from 'lodash/fp';
import { CONTENT } from '../contentActions';

import { ReduxState } from '$redux/store';
import { FOLDER } from '$redux/storeNames';

export const stateSelector: (state: ReduxState) => FolderState = pipe(
    property(CONTENT),
    property(FOLDER)
);

export const folderSelector = pipe(stateSelector, property('folder'));

export const folderContentSelector = pipe(stateSelector, property('content'), (folderContent) => ({
    folderContent
}));

export const folderPreferencesSelector = pipe(
    folderSelector,
    pick([
        'encrypted',
        'metadata',
        'metadata_user',
        'watermark',
        'export_mode',
        'default_presets',
        'admittance',
        'export',
        'triggers',
        'labels',
        'tags',
        'download',
        'file_export'
    ]),
    (folderPreferences) => ({ folderPreferences })
);

export const folderPluginsSelector: (state: ReduxState) => { folderPlugins: string[] } = pipe(
    stateSelector,
    ({ plugins }) => ({
        folderPlugins: plugins
    })
);

export const folderHasAvidSelector = pipe(
    stateSelector,
    property('plugins'),
    find((plugin) => plugin === 'comments_avid'),
    (hasAvid) => ({ folderHasAvid: !!hasAvid })
);

export const folderRoleSelector = pipe(stateSelector, ({ folder }) => ({
    folderRole: folder?.role
}));

export const canDownloadSelector = pipe(stateSelector, property('folder.canDownload'));

export const isSharedSelector = pipe(stateSelector, pick('isShared'));

export const folderRightsSelector = pipe(stateSelector, property('rights'), (folderRights) => ({
    folderRights
}));

export const folderUuidSelector = pipe(stateSelector, (folderState) => ({
    folderUuid: folderState.folder?.uuid
}));

export const folderOwnerUuidSelector = pipe(
    stateSelector,
    ({ folder }) => folder?.user.uuid,
    (folderOwnerUuid) => ({ folderOwnerUuid })
);
export const folderOwnerEmailSelector = pipe(
    stateSelector,
    ({ folder }) => folder?.user.email,
    (folderOwnerEmail) => ({ folderOwnerEmail })
);
export const folderOwnerSelector = pipe(
    stateSelector,
    ({ folder }) => folder?.user,
    (folderOwner) => ({ folderOwner })
);
export const copySelector = pipe(stateSelector, pick('copy'));

export const folderMembersSelector = pipe(stateSelector, property('members'), (folderMembers) => ({
    folderMembers
}));

export const trashViewSelector = pipe(stateSelector, pick(['content', 'name', 'parent']));

export const folderProjectSelector = pipe(stateSelector, (f) => f.folder?.project || null);
