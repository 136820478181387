import styles from './SidePanelList.module.css';
import React from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { ContextMenuActions } from '@he-novation/design-system/components/widgets/ContextMenu/ContextMenu';
import cn from 'classnames';

import Link from '$components/router/Link';

export type SidePanelListItem = {
    [other: string]: unknown;
    uuid: string;
    className?: string;
    href?: string;
    name: string;
    count: number;
};

type SidePanelListProps = {
    canEdit?: (item: SidePanelListItem) => boolean;
    deleteItem?: (item: SidePanelListItem) => void;
    editItem?: (item: SidePanelListItem) => void;
    className?: string;
    contextMenuActions?: ContextMenuActions;
    items: SidePanelListItem[];
};

export function SidePanelList({
    editItem,
    deleteItem,
    items,
    canEdit,
    className
}: SidePanelListProps) {
    return (
        <ul className={cn(styles.sidePanelList, className)}>
            {items.map((item, i) => (
                <li key={i} className={item.className}>
                    <Link href={item.href} className={styles.link}>
                        <div className={styles.count}>{item.count}</div>
                        <h3 className={styles.name}>{item.name}</h3>
                    </Link>
                    {editItem && (!canEdit || canEdit(item)) && (
                        <Button
                            icon="pencil"
                            className={styles.edit}
                            tone={ButtonTone.Hoverable}
                            onClick={() => editItem(item)}
                        />
                    )}
                    {deleteItem && (!canEdit || canEdit(item)) && (
                        <Button
                            icon="trash"
                            className={styles.delete}
                            tone={ButtonTone.Alert}
                            onClick={() => deleteItem(item)}
                        />
                    )}
                </li>
            ))}
        </ul>
    );
}
