import styles from './FormNoteLogin.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    FormField,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { asyncEmailVerify, asyncUserLogin } from '@he-novation/front-shared/async/user.async';
import cn from 'classnames';

import { useTranslate } from '$hooks/useTranslate';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { fetchAccount, fetchUser } from '$redux/user/userActions';

type FormNoteLoginProps = {
    setPostedAs: (postedAs: string | null) => void;
};

export function FormNoteLogin({ setPostedAs }: FormNoteLoginProps) {
    const [email, setEmail] = useState<string | undefined>();
    const [needsPassword, setNeedsPassword] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<string | null>(null);
    const dispatch = useDispatch();
    const { t } = useTranslate();

    useEffect(() => {
        const localStorageEmail = localStorage.getItem('castEmail');
        if (localStorageEmail) setEmail(localStorageEmail);
    }, []);

    useEffect(() => {
        if (email) {
            localStorage.setItem('castEmail', email);
            setLoginError(null);
            asyncEmailVerify(email)
                .catch(() => {
                    return { status: 'not_found' };
                })
                .then((r) => {
                    if (r.status === 'exists') {
                        setNeedsPassword(true);
                        setPostedAs(null);
                    } else {
                        setNeedsPassword(false);
                        setPostedAs(email);
                    }
                });
        }
    }, [email]);

    const onFocus = () => {
        dispatch(toggleKeyboardListeners(false));
    };

    const formId = 'form-note-login';

    return (
        <ModularForm
            id={formId}
            className={cn(styles.formNoteLogin, formStyles.light)}
            onSubmit={(e, data) => {
                e.preventDefault();

                asyncUserLogin(data.postedAs, data.password)
                    .then(() => {
                        dispatch(fetchAccount());
                        dispatch(fetchUser());
                    })
                    .catch((_) => {
                        setLoginError(t('cast.Login failed'));
                    });
            }}
        >
            <FormField
                formId={formId}
                type="recaptcha"
                name="recaptcha"
                siteKey={process.env.RECAPTCHA_FRONT_KEY}
                action={'verifyEmailOrPassword'}
            />

            <FormField
                formId={formId}
                id={`${formId}-email`}
                label={t('common.E-mail')}
                onFocus={onFocus}
                type={'email'}
                name="postedAs"
                value={email}
                wrapperProps={{ style: { marginTop: 0 } }}
                onBlur={(e: SyntheticEvent<HTMLInputElement, Event>) => {
                    dispatch(toggleKeyboardListeners(true));
                    setEmail(e.currentTarget.value);
                }}
                validation={{
                    required: true
                }}
            />

            {email && needsPassword && (
                <div className={styles.login}>
                    <FormField
                        formId={formId}
                        label={t('common.Password')}
                        onFocus={onFocus}
                        onBlur={(_) => {
                            dispatch(toggleKeyboardListeners(true));
                        }}
                        type={'password'}
                        name="password"
                        error={loginError || undefined}
                        onChange={(e) => {
                            console.log('loginError', loginError);
                            if (loginError) setLoginError(null);
                        }}
                        validation={{
                            required: true
                        }}
                    />

                    <Button type="submit" tone={ButtonTone.Primary}>
                        {t('cast.Login')}
                    </Button>
                </div>
            )}
        </ModularForm>
    );
}
