export const getDisplayName = (
    {
        firstname,
        lastname,
        email
    }: {
        firstname?: string | null;
        lastname?: string | null;
        email?: string | null;
    },
    withEmail?: boolean
) => {
    let displayName;
    if (firstname && lastname) {
        displayName = `${firstname} ${lastname}`;
        if (withEmail) displayName += ` <${email}>`;
    } else displayName = email;

    return displayName;
};
