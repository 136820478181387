import { ProjectsStateCurrentProject } from '@he-novation/config/types/project.types';
import { getAllDaysBetweenDates } from '@he-novation/utils/datetime';
import { atom } from 'jotai';

import { FilterName, FilterValueId } from '$components/Filters/FilterCheckboxes';
import {
    CalendarFilterEnum,
    CalendarItemColorType,
    CalendarZoom,
    GlobalRowsType,
    ScrollCalendarGrouper,
    ScrollCalendarItemType
} from '$components/ScrollCalendar/ScrollCalendar.types';

export const calendarZoomAtom = atom<CalendarZoom>(CalendarZoom.Weeks);
export const calendarGrouperAtom = atom<ScrollCalendarGrouper>(ScrollCalendarGrouper.Users);
export const calendarActiveFiltersAtom = atom<Record<FilterName, FilterValueId[]>>({
    [CalendarFilterEnum.Teams]: [],
    [CalendarFilterEnum.Labels]: [],
    [CalendarFilterEnum.Authors]: []
});
export const calendarActiveGrouperFilters = atom<string[]>([]);
export const calendarItemsAtom = atom<ScrollCalendarItemType[]>([]);
export const calendarItemColorTypeAtom = atom<CalendarItemColorType>(CalendarItemColorType.Label);
export const calendarDateRangeAtom = atom<Date[]>([new Date(), new Date()]);
export const calendarProjectAtom = atom<ProjectsStateCurrentProject | undefined>(undefined);
export const calendarGlobalRowsAtom = atom<GlobalRowsType | undefined>(undefined);
export const calendarActiveCenteredDateAtom = atom<Date>(new Date());
export const calendarSearchTermAtom = atom<string>('');

// make a derived atom for daysInterval
export const calendarDaysIntervalAtom = atom((get) => {
    const dateRange = get(calendarDateRangeAtom);
    return getAllDaysBetweenDates(dateRange[0], dateRange[1]).map((date) => date.toISOString());
});

// make a derived atom for filteredItems
export const calendarFilteredItemsAtom = atom((get) => {
    const items = get(calendarItemsAtom);
    const activeFilters = get(calendarActiveFiltersAtom);

    const filterByLabels = (items: ScrollCalendarItemType[]): ScrollCalendarItemType[] => {
        if (activeFilters?.Labels?.length) {
            const filtered = items?.filter((item) =>
                item?.labels?.some((label) => activeFilters?.Labels?.includes(label.uid))
            );
            if (activeFilters?.Labels?.includes('none')) {
                return [
                    ...(filtered || []),
                    ...(items?.filter((item) => !item?.labels?.length) || [])
                ];
            }
            return filtered;
        }
        return items;
    };

    const filterByTeams = (items: ScrollCalendarItemType[]): ScrollCalendarItemType[] => {
        if (activeFilters.Teams.length) {
            const filtered = items?.filter((item) =>
                item.teams?.some((team) => activeFilters?.Teams?.includes(team?.uuid))
            );
            if (activeFilters?.Teams?.includes('none')) {
                return [
                    ...(filtered || []),
                    ...(items.filter((item) => !item.teams?.length) || [])
                ];
            }
            return filtered;
        }
        return items;
    };

    const filterByAuthors = (items: ScrollCalendarItemType[]): ScrollCalendarItemType[] => {
        if (activeFilters.Authors.length) {
            const filtered = items?.filter((item) =>
                activeFilters?.Authors?.includes(item?.owner?.uuid)
            );
            if (activeFilters?.Authors?.includes('none')) {
                return [...(filtered || []), ...(items?.filter((item) => !item?.owner) || [])];
            }
            return filtered;
        }
        return items;
    };

    return filterByAuthors(filterByTeams(filterByLabels(items)));
});
