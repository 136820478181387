import {
    pathWithParams,
    publicApiV1Paths,
    subtitlesPaths
} from '@he-novation/config/paths/herawApiPaths';
import { Asset } from '@he-novation/config/types/asset.types';
import {
    SubtitleEntryBody,
    SubtitleQuerySchema,
    SubtitleUpdateSchema,
    SubtitleUploadUrlBody
} from '@he-novation/config/types/payloads/subtitle.payload';
import { isBinary } from '../utils/isBinary';
import { apiFetch } from './apiFetch';
export const subtitleCreate = ({ fileUuid, fileVersion, locale, name, copy }) =>
    apiFetch(subtitlesPaths.single, {
        method: 'POST',
        body: JSON.stringify({
            fileUuid,
            fileVersion,
            locale,
            name,
            copy
        })
    });

export const subtitleEntryCreate = (subtitleUuid, data: SubtitleEntryBody) =>
    apiFetch(pathWithParams(subtitlesPaths.entrySingle, { subtitleUuid }), {
        method: 'POST',
        body: JSON.stringify(data)
    });

export const subtitleEntryDelete = (subtitleUuid, entryUuid) =>
    apiFetch(pathWithParams(subtitlesPaths.entrySpecific, { subtitleUuid, entryUuid }), {
        method: 'DELETE'
    });

export const subtitleEntryUpdate = (
    subtitleUuid: string,
    entryUuid: string,
    body: SubtitleUpdateSchema
) =>
    apiFetch(pathWithParams(subtitlesPaths.entrySpecific, { subtitleUuid, entryUuid }), {
        method: 'PUT',
        body
    });

export async function asyncSubtitlesUpload(
    fileUuid: string,
    fileVersion: number,
    locale: string,
    file: File
): Promise<void> {
    const { link, headers, subtitleUuid } = await apiFetch(subtitlesPaths.upload, {
        method: 'POST',
        body: {
            fileUuid,
            fileVersion,
            subtitleFileName: file.name,
            subtitleFileSize: file.size,
            isBinary: await isBinary(file),
            locale
        } satisfies SubtitleUploadUrlBody
    });

    const response = await fetch(link, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type,
            ...headers
        },
        body: file
    });

    if (response.status > 299) throw response;

    await apiFetch(subtitlesPaths.transcode, {
        params: { subtitleUuid },
        method: 'PUT'
    });
}

export const deleteSubtitles = (subtitleUuid: string) =>
    apiFetch(pathWithParams(subtitlesPaths.specific, { subtitleUuid }), {
        method: 'DELETE'
    });

export const asyncOpenApiSubtitleFetch = (
    apiKey: string,
    subtitleUuid: string,
    query?: SubtitleQuerySchema,
    rawResponse?: boolean
) =>
    apiFetch(publicApiV1Paths.subtitle.specific, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: {
            subtitleUuid
        },
        query,
        rawResponse
    });

/**
 * Returns null if export has not been generated, other returns the asset with its url
 */
export const asyncBurnSubtitles = async (
    fileUuid: string,
    fileVersion: number,
    subtitleUuid: string
): Promise<Asset | null> =>
    apiFetch(subtitlesPaths.burn, {
        method: 'POST',
        params: { fileUuid, fileVersion, subtitleUuid }
    });
