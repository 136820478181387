import styles from './FileStatus.module.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FileStatus as FStatus } from '@he-novation/config/types/db/enums';
import { FolderRole } from '@he-novation/config/types/folder.types';
import { acl } from '@he-novation/config/utils/acl';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { DiscText } from '@he-novation/design-system/components/text/DiscText/DiscText';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { fileStatusUpdate, fileVoteFinal } from '@he-novation/front-shared/async/file.async';
import cn from 'classnames';

import { Translator, useTranslate } from '$hooks/useTranslate';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { combinedPluginsSetSelector } from '$redux/content/contentSelectors';
import { folderMembersSelector } from '$redux/content/folder/folderSelectors';
import { currentUserSelector } from '$redux/user/userSelectors';

type FileStatusProps = {
    role?: FolderRole;
    className?: string;
    fileUuid: string;
    fileVersion: number;
    fileStatus: FStatus;
    finalVotes: { uuid: string; email: string }[];
    direction?: [DirectionX, DirectionY];
    displayDefault?: boolean;
    withArrow?: boolean;
    responsive?: boolean;
};

export const fileStatusToColor = (status: FStatus, votes: number) => {
    switch (status) {
        case FStatus.default:
            return '#86898e';
        case FStatus.retake:
            return '#ff335e';
        case FStatus.in_progress:
            return '#da73e0';
        case FStatus.to_validate:
            if (votes) return '#49f281';
            return '#51cef6';
        case FStatus.final:
            return '#49f281';
    }
};

const statusToAction = (
    fileStatus: FStatus,
    finalVotes: number,
    status: FStatus,
    setStatus: (status: FStatus) => void,
    t: Translator
): ActionType => {
    return {
        id: status,
        children: (
            <DiscText outline={fileStatus !== status} color={fileStatusToColor(status, finalVotes)}>
                {t(`folder.__FILE_STATUS_${status.toUpperCase()}`)}
            </DiscText>
        ),
        disabled: fileStatus === status,
        onClick: () => setStatus(status)
    };
};

export const folderRoleToStatusActions = (
    fileUuid: string,
    fileVersion: number,
    fileStatus: FStatus,
    finalVotes: { uuid: string; email: string }[],
    currentUserUuid: string,
    role: FolderRole | undefined,
    cb: ((status: FStatus) => void) | undefined,
    t: Translator
): ActionType[] => {
    const actions: ActionType[] = [];
    const setStatus = (_status: FStatus) => {
        fileStatusUpdate(fileUuid, fileVersion, { status: _status });
        cb?.(_status);
    };

    if (acl.files.setStatus(role)) {
        actions.push(
            statusToAction(fileStatus, finalVotes.length, FStatus.default, setStatus, t),
            statusToAction(fileStatus, finalVotes.length, FStatus.retake, setStatus, t),
            statusToAction(fileStatus, finalVotes.length, FStatus.in_progress, setStatus, t),
            statusToAction(fileStatus, finalVotes.length, FStatus.to_validate, setStatus, t)
        );
        if (
            acl.files.setFinal(role) ||
            (acl.files.voteFinal(role) && !finalVotes.find(({ uuid }) => uuid === currentUserUuid))
        )
            actions.push(
                statusToAction(
                    fileStatus,
                    finalVotes.length,
                    FStatus.final,
                    acl.files.setFinal(role)
                        ? (status) => setStatus(status)
                        : () => fileVoteFinal(fileUuid, fileVersion, { final: true }),
                    t
                )
            );
    }
    return actions;
};

export function FileStatus({
    fileUuid,
    fileVersion,
    fileStatus: _fileStatus,
    finalVotes,
    role,
    className,
    direction,
    displayDefault,
    withArrow,
    responsive
}: FileStatusProps) {
    const { t } = useTranslate();

    const combinedPlugins = useSelector(combinedPluginsSetSelector);
    const { isSmallScreen } = useSelector(isSmallScreenSelector);
    const { folderMembers } = useSelector(folderMembersSelector);
    const { currentUser } = useSelector(currentUserSelector);

    const [fileStatus, setFileStatus] = React.useState<FStatus>(_fileStatus);

    useEffect(() => {
        setFileStatus(_fileStatus);
    }, [_fileStatus]);

    if (!combinedPlugins.has('versioning') || (fileStatus === FStatus.default && !displayDefault))
        return null;

    const actions = folderRoleToStatusActions(
        fileUuid,
        fileVersion,
        fileStatus,
        finalVotes,
        currentUser.uuid,
        role,
        (status) => setFileStatus(status),
        t
    );

    const votesInProgress = fileStatus === FStatus.to_validate && finalVotes.length > 0;

    const label = (
        <span className={styles.label}>
            {votesInProgress
                ? t(`folder.__FILE_STATUS_${FStatus.final.toUpperCase()}`)
                : t(`folder.__FILE_STATUS_${fileStatus.toUpperCase()}`)}
        </span>
    );
    const votes =
        votesInProgress && folderMembers ? (
            <span className={styles.votes}>{`${finalVotes.length}/${
                folderMembers.filter((m) => acl.files.voteFinal(m.role)).length
            }`}</span>
        ) : undefined;

    const chevronColor = isSmallScreen && responsive ? 'white' : 'black';

    return (
        <MultiActionButton
            style={{ color: fileStatusToColor(fileStatus, finalVotes.length) }}
            disabled={actions.length === 0}
            className={cn(
                styles.status,
                className,
                responsive && styles.responsive,
                'rotate-icon-270'
            )}
            actions={actions}
            direction={direction}
            icon={withArrow ? [{ name: 'chevron', stroke: chevronColor, fill: 'none' }] : undefined}
            iconAfter
        >
            {label}
            {votes}
        </MultiActionButton>
    );
}
