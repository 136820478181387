import paths, {
    authPaths,
    publicApiV1Paths,
    userNotificationsPaths,
    userPaths
} from '@he-novation/config/paths/herawApiPaths';
import { indexLink } from '@he-novation/config/paths/herawFrontUris';
import { signInLink } from '@he-novation/config/paths/herawLoginPaths';
import {
    AuthLoginPayload,
    AuthPasswordUpdatePayload
} from '@he-novation/config/types/payloads/auth.payload';
import {
    UserInfoSchema,
    UserNotificationSettingsSchema,
    UserPreferencesSchema,
    UserStorageQuery,
    UserVerifySchema
} from '@he-novation/config/types/payloads/user.payload';
import { PublicPluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { UserAppInfos, type UserLicense, UserStorage } from '@he-novation/config/types/user.types';
import { load } from 'recaptcha-v3';
import { apiFetch } from './apiFetch';

export const asyncOIDCLogin = (username: string, password: string, provider: string) =>
    apiFetch(userPaths.oidcLogin, {
        method: 'POST',
        params: {
            provider
        },
        body: {
            username,
            password
        }
    });
export const asyncUserLogin = (email: string, password: string, twoFAKey?: string) =>
    apiFetch(authPaths.login, {
        method: 'POST',
        body: {
            email,
            password,
            totp: twoFAKey
        } satisfies AuthLoginPayload
    });

export const fetchUserAppInfos = (): Promise<UserAppInfos> =>
    apiFetch(paths.users.appInfos).then((r) => ({
        ...r,
        created: new Date(r.created),
        passwordReset: r.passwordReset ? new Date(r.passwordReset) : null
    }));

export const requestDelete = (cb?: () => void) =>
    apiFetch(paths.users.root, {
        method: 'DELETE'
    }).then(() => typeof cb === 'function' && cb());

export const paymentCancelSubscription = () =>
    apiFetch(`api/payment/cancel/subscription`, {
        method: 'POST'
    });

export const fetchAccount = () =>
    apiFetch(paths.users.account, {
        method: 'GET'
    });

export const userLicensesFetch = (): Promise<UserLicense[]> => apiFetch(paths.users.licenses);

export const fetchStorage = async (folderUuid?: string, sum?: boolean): Promise<UserStorage> =>
    apiFetch(userPaths.storage, { query: { folderUuid, sum } satisfies UserStorageQuery });

export const fetchPlugins = (): Promise<string[]> => apiFetch(userPaths.plugins);

export const fetchPluginsEnabled = (): Promise<PublicPluginConfigurationType[]> =>
    apiFetch(userPaths.pluginConfigurations);

export const contactAdmin = async (message) =>
    apiFetch(`api/user/contact/admin`, {
        method: 'POST',
        body: JSON.stringify({
            message
        })
    });

export const updateProfile = async (profile: UserInfoSchema) =>
    apiFetch(paths.users.profile, {
        method: 'PUT',
        body: profile
    });

export const updatePassword = async (body: AuthPasswordUpdatePayload) =>
    apiFetch(authPaths.password, {
        method: 'PUT',
        body
    });

export const updateUserPreferences = async (body: UserPreferencesSchema) =>
    apiFetch(userPaths.preferences, {
        method: 'PATCH',
        body
    });

export const fetchGeoIp = async () =>
    window
        .fetch('https://api.ipstack.com/check?access_key=3098b60efb362a9194cf15bb592954c3')
        .then((r) => r.json());

export const cancelLicense = (userUuid: string) =>
    apiFetch(userPaths.license, {
        method: 'DELETE',
        body: {
            userUuid
        }
    });

export const updateFoldersLiveNotify = (livenotify) =>
    apiFetch(`api/user/update/livenotify`, {
        method: 'POST',
        body: JSON.stringify({ livenotify })
    });

export const signUp = async ({
    firstname,
    lastname,
    email,
    phone,
    firm,
    role,
    recaptcha,
    locale,
    plan,
    picture
}: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    firm: string;
    role: string;
    recaptcha: string;
    locale: string;
    plan: string;
    picture?: string;
}) =>
    apiFetch(paths.users.root, {
        method: 'POST',
        maxRedirects: 0,
        useRawError: true,
        body: {
            firstname,
            lastname,
            email,
            phone,
            firm,
            role,
            recaptcha,
            locale,
            plan,
            picture
        }
    })
        .then(() => {
            window.location.href = process.env.APP_URL + indexLink();
        })
        .catch((e) => {
            window.location.href = signInLink(locale, {
                email,
                error: typeof e.data === 'string' ? e.data : e.data?.message
            });
        });

export const logout = (locale?: string) =>
    apiFetch(authPaths.logout, { method: 'POST' }).then(() => {
        window.location.href = signInLink(locale);
    });

export const requestAdmittance = ({ signupData, folderUuid }) =>
    apiFetch(paths.folder.admittance, {
        method: 'POST',
        redirect: false,
        params: { folderUuid },
        body: {
            signupData
        }
    });

export const asyncTranslationCreditsFetch = () => apiFetch(userPaths.translationCredits);

export const asyncEmailVerify = async (email: string, password?: string | null) => {
    const recaptchaInstance = await load(process.env.RECAPTCHA_FRONT_KEY!);
    const recaptcha = await recaptchaInstance.execute('verifyEmailOrPassword');
    return apiFetch(userPaths.verify, {
        method: 'POST',
        body: {
            email,
            password,
            recaptcha
        } satisfies UserVerifySchema
    });
};

export const asyncOpenApiUserFetch = async (email: string, password: string) =>
    apiFetch(publicApiV1Paths.auth.user, {
        method: 'POST',
        body: {
            email,
            password
        } satisfies AuthLoginPayload
    });

export function asyncUserNotificationSettingsFetch(
    clientName: string
): Promise<UserNotificationSettingsSchema> {
    return apiFetch(userNotificationsPaths.userNotifications, {
        params: {
            clientName
        }
    });
}
export function asyncProjectUserNotificationSettingsFetch(
    clientName: string,
    projectUuid: string
): Promise<UserNotificationSettingsSchema | null> {
    return apiFetch(userNotificationsPaths.projectNotifications, {
        params: {
            clientName,
            projectUuid
        }
    });
}
export function asyncProjectUserNotificationSettingsCreate(
    clientName: string,
    projectUuid: string,
    body: UserNotificationSettingsSchema
): Promise<null> {
    return apiFetch(userNotificationsPaths.projectNotifications, {
        method: 'POST',
        params: {
            clientName,
            projectUuid
        },
        body
    });
}

export function asyncUserNotificationSettingsUpdate(
    clientName: string,
    projectUuid: string | null,
    body: UserNotificationSettingsSchema
): Promise<null> {
    return apiFetch(
        projectUuid
            ? userNotificationsPaths.projectNotifications
            : userNotificationsPaths.userNotifications,
        {
            method: 'PUT',
            params: {
                clientName,
                projectUuid
            },
            body
        }
    );
}
