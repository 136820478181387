import styles from './FormComment.module.css';
import React, { useRef, useState } from 'react';
import { ModularForm } from 'react-modular-forms';
import { useDispatch } from 'react-redux';
import type { Comment } from '@he-novation/config/types/note.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { useTranslate } from '../../../../../hooks/useTranslate';

import Chips from '$components/base/Chips/Chips';
import { AttachmentButton } from '$components/SidePanel/SidePanelFile/SidePanelNotes/AttachmentButton/AttachmentButton';
import { useCreateNotesAndComments } from '$hooks/useNotes';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';

type FormCommentProps = {
    noteUuid: string;
    cancel: () => void;
    comment?: Omit<Comment, 'note' | 'file'>;
};

export function FormComment({ noteUuid, comment, cancel }: FormCommentProps) {
    const formId = 'form-comment';
    const [isLoading, setIsLoading] = useState(false);
    const [attachments, setAttachments] = useState<File[]>([]);
    const draftRef = useRef<false | undefined>(undefined);
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const { commentCreate, commentEdit } = useCreateNotesAndComments();

    return (
        <ModularForm
            id={formId}
            className={styles.form}
            onSubmit={async (
                e,
                data: {
                    content: string;
                }
            ) => {
                e.preventDefault();
                setIsLoading(true);
                comment
                    ? await commentEdit(noteUuid, comment.uuid, {
                          content: data.content,
                          isDraft: draftRef.current,
                          attachments,
                          tags: []
                      })
                    : await commentCreate(noteUuid, {
                          content: data.content,
                          shouldNotify: false,
                          isDraft: false,
                          attachments
                      });
                cancel();
            }}
        >
            <FormField
                formId={formId}
                name="content"
                type="textarea"
                readOnly={isLoading}
                value={comment?.content}
                autoFocus
                onFocus={() => {
                    dispatch(toggleKeyboardListeners(false));
                }}
                onBlur={() => {
                    dispatch(toggleKeyboardListeners(true));
                }}
            />
            <div className={styles.chips}>
                {attachments.map((a, i) => (
                    <Chips
                        key={a.name + i}
                        onDelete={() =>
                            setAttachments(
                                attachments.filter((attachment) => attachment.name !== a.name)
                            )
                        }
                    >
                        <Icon icon="paper_clip" stroke="white" />
                        {a.name}
                    </Chips>
                ))}
            </div>
            <footer className={styles.footer}>
                <div className={styles.options}>
                    <AttachmentButton attachments={attachments} setAttachments={setAttachments} />
                </div>
                <div className={styles.buttons}>
                    <Button
                        id="submit-note-draft"
                        tone={ButtonTone.Secondary}
                        onClick={cancel}
                        type="button"
                        isLoading={isLoading}
                    >
                        {t('common.Cancel')}
                    </Button>
                    {comment && (
                        <Button tone={ButtonTone.Primary} isLoading={isLoading}>
                            {t('common.Save')}
                        </Button>
                    )}
                    {(!comment || comment.draft) && (
                        <Button
                            onClick={() => {
                                draftRef.current = false;
                            }}
                            tone={ButtonTone.Primary}
                            isLoading={isLoading}
                        >
                            {t('common.Publish')}
                        </Button>
                    )}
                </div>
            </footer>
        </ModularForm>
    );
}
