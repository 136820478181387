import styles from './FilterCheckboxes.module.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckboxDropdown } from '@he-novation/design-system/components/form/CheckboxDropdown/CheckboxDropdown';
import { CheckboxSearchableList } from '@he-novation/design-system/components/form/CheckboxList/CheckboxSearchableList';
import { Checkbox } from '@he-novation/design-system/types';
import { Direction } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';

import Accordion from '$components/Accordion/Accordion';
import { useSearch } from '$hooks/useSearch';
import { useTranslate } from '$hooks/useTranslate';

export type FilterName = string;
export type FilterValueId = string;
export type FilterValue = {
    id: FilterValueId;
    label: string;
};

export type FiltersCheckboxProps = {
    formId: string;
    filterValues: FilterValue[];
    filterName: FilterName;
    activeFilters: Record<FilterName, FilterValueId[]>;
    onSetFilter: (filterName: string, filterValues?: string[], checkedValues?: string[]) => void;
    label?: React.ReactNode | string;
    selectedLabel?:
        | React.ReactNode
        | ((activeFilterValues: FilterValue[]) => React.ReactNode)
        | string;
    icon?: string;
    className?: string;
    fieldClassName?: string;
    direction?: Direction;
    hasSearch?: boolean;
    hasSelectAllButton?: boolean;
};

export enum FilterCheckboxTag {
    Accordion,
    DropDown
}

type FiltersCheckboxPropsWithTag = {
    tag: FilterCheckboxTag;
} & FiltersCheckboxProps;

export function FilterCheckboxes({
    tag,
    formId,
    filterValues,
    filterName,
    activeFilters,
    onSetFilter,
    label,
    selectedLabel,
    icon,
    className,
    fieldClassName,
    direction,
    hasSearch,
    hasSelectAllButton
}: FiltersCheckboxPropsWithTag) {
    const [activeFilterValues, setActiveFilterValues] = useState<FilterValue[]>(
        filterValues.filter(
            ({ id }) => !activeFilters[filterName] || activeFilters[filterName].includes(id)
        )
    );

    const { t } = useTranslate();

    const { search, setSearch, resetSearch } = useSearch();

    useEffect(() => {
        setActiveFilterValues(
            filterValues.filter(
                ({ id }) => !activeFilters[filterName] || activeFilters[filterName].includes(id)
            )
        );
    }, [filterValues, activeFilters, filterName]);

    const onFilterChecked = useCallback(
        (checked: Checkbox[]) => {
            const newFilterValues = checked
                .map(({ value }) => filterValues.find(({ id }) => id === value))
                .filter((filterValue) => !!filterValue);
            setActiveFilterValues(newFilterValues);

            onSetFilter(
                filterName,
                newFilterValues.length && newFilterValues.length !== filterValues.length
                    ? newFilterValues.map(({ id }) => id)
                    : undefined,
                newFilterValues.map(({ id }) => id)
            );
        },
        [filterName, filterValues, activeFilters]
    );

    const checkboxes: Checkbox[] = useMemo(
        () =>
            filterValues.map((filterValue) => ({
                ...filterValue,
                value: filterValue.id,
                checked:
                    !activeFilters[filterName] ||
                    activeFilters[filterName]?.includes(filterValue.id)
            })),
        [filterValues, filterName, activeFilters]
    );

    const selectedItems = selectedLabel ? (
        typeof selectedLabel === 'function' ? (
            selectedLabel(activeFilterValues)
        ) : (
            selectedLabel
        )
    ) : (
        <span className={styles.selectedLabel}>
            {activeFilterValues.length === filterValues.length
                ? `(${t('common.All')})`
                : activeFilterValues.length
                ? `(${activeFilterValues.map(({ label }) => label).join(', ')})`
                : `(${t('common.All')})`}
        </span>
    );

    const title = typeof label === 'string' ? <span className={styles.label}>{label}</span> : label;

    switch (tag) {
        case FilterCheckboxTag.Accordion:
            return (
                <Accordion
                    title={
                        <>
                            {title}
                            {selectedItems}
                        </>
                    }
                    icon={icon}
                    key={formId}
                    className={styles.filtersCheckbox}
                    contentClassName={className}
                >
                    <CheckboxSearchableList
                        checkboxes={checkboxes}
                        formId={formId}
                        onChange={onFilterChecked}
                        checkboxClassName={fieldClassName}
                        search={
                            hasSearch
                                ? {
                                      value: search,
                                      setValue: setSearch,
                                      reset: resetSearch
                                  }
                                : undefined
                        }
                        hasSelectAllButton={hasSelectAllButton}
                    />
                </Accordion>
            );
        case FilterCheckboxTag.DropDown:
            return (
                <CheckboxDropdown
                    checkboxes={checkboxes}
                    formId={formId}
                    label={
                        <>
                            {title}
                            {selectedItems}
                        </>
                    }
                    icon={icon}
                    onChange={onFilterChecked}
                    className={cn(styles.filtersCheckbox, className)}
                    checkboxClassName={fieldClassName}
                    dropdownClassName={styles.filtersCheckboxDropdown}
                    direction={direction}
                    search={
                        hasSearch
                            ? {
                                  value: search,
                                  setValue: setSearch,
                                  reset: resetSearch
                              }
                            : undefined
                    }
                    hasSelectAllButton={hasSelectAllButton}
                />
            );
    }
}
