import styles from './Main.module.css';
import React, { ReactNode } from 'react';
import cn from 'classnames';
import { usePanel } from '../../../hooks/usePanel';

export const Main = ({ children }: { children?: ReactNode | ReactNode[] }) => {
    return (
        <div id="main" className={styles.main}>
            {children}
        </div>
    );
};

Main.Content = function MainContent({ children }: { children?: ReactNode | ReactNode[] }) {
    const { panel } = usePanel();
    return (
        <div
            id="main-content"
            className={cn(
                styles.content,
                panel ? styles.hasOpenPanel + ' has-open-panel' : undefined
            )}
        >
            {children}
        </div>
    );
};
