import styles from './FormField.module.scss';
import { Icon, IconProp } from '../../graphics/Icon/Icon';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import { ModularFormField } from 'react-modular-forms';
import { Theme } from '../../../enums';
import { ExtractProps } from '../../../utils/extractProps';
import { configureReactModularForms } from './configureReactModularForms';

export { ModularForm } from 'react-modular-forms';

export type FormFieldProps = ExtractProps<typeof ModularFormField> & {
    theme?: Theme;
    icon?: IconProp;
    children?: ReactNode | ReactNode[];
    size?: 'half' | 'full';
    leftMargin?: boolean;
    noTopMargin?: boolean;
    after?: ReactNode;
};

configureReactModularForms();

export const FormField = ({
    className,
    icon,
    size = 'full',
    children,
    leftMargin,
    noTopMargin,
    label,
    disabled,
    ...props
}: FormFieldProps) => {
    let _label = label && props?.validation?.required ? label + ' *' : label;
    _label = icon && !label ? ' ' : label;

    return (
        <ModularFormField
            className={cn(
                styles.field,
                className,
                icon && 'has-icon',
                `size-${size}`,
                leftMargin && 'has-left-margin',
                noTopMargin && 'no-top-margin',
                icon && !label && 'is-empty',
                disabled && 'is-disabled'
            )}
            label={_label}
            disabled={disabled}
            {...props}
        >
            {icon ? (
                <>
                    <Icon icon={icon} />
                    {children}
                </>
            ) : (
                children
            )}
        </ModularFormField>
    );
};

type FormFieldsProps = {
    formId: string;
    fields: any[];
    theme?: Theme;
};

export const FormFields = ({ formId, fields }: FormFieldsProps) => {
    return (
        <>
            {fields
                .filter((f) => f && f.type)
                .map((f, i) => (
                    <FormField formId={formId} {...f} key={formId + (f.name || f.id) + i} />
                ))}
        </>
    );
};
