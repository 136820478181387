import styles from './Toast.module.scss';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Button, ButtonProps, ButtonTone } from '../../buttons/Button/Button';
import __ from '@he-novation/utils/i18n';
import { useTranslate } from '../../../hooks/useTranslate';

export type ToastProps = {
    className?: string;
    content?: ReactNode;
    title?: string;
    autoCloseAfterMs?: number;
    style?: any;
    onClose?: () => void;
    buttons?: ButtonProps[] | ((close: () => void) => ButtonProps[]);
};

const DEFAULT_AUTO_CLOSE_MS = 3000;

export const Toast: React.FC<ToastProps> = ({
    className,
    title,
    content,
    autoCloseAfterMs = DEFAULT_AUTO_CLOSE_MS,
    style,
    onClose,
    buttons: _buttons = []
}) => {
    const [isMounted, setIsMounted] = React.useState<boolean>(true);
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [isFading, setIsFading] = React.useState<boolean>(false);
    const timeoutRef = useRef<{ [timeout: string]: any }>({});

    useEffect(() => {
        setIsVisible(true);
        if (autoCloseAfterMs)
            timeoutRef.current.delayTimeout = setTimeout(() => close(), autoCloseAfterMs);
        return () => {
            clearTimeout(timeoutRef.current.delayTimeout);
            clearTimeout(timeoutRef.current.closeTimeout);
        };
    }, [autoCloseAfterMs]);

    const close = useCallback(() => {
        setIsFading(true);
        if (typeof onClose === 'function') {
            onClose();
        }
        timeoutRef.current.closeTimeout = setTimeout(() => setIsMounted(false), 500);
    }, [onClose]);

    const buttons = typeof _buttons === 'function' ? _buttons(close) : _buttons;
    const { t } = useTranslate();

    if (buttons.length === 0) {
        buttons.push({
            className: styles.close,
            children: t('Close'),
            tone: ButtonTone.Neutral,
            onClick: close
        });
    }

    return isMounted
        ? ReactDOM.createPortal(
              <div
                  className={classNames(styles.toast, 'c-toast', className, {
                      'is-visible': isVisible,
                      'is-fading': isFading
                  })}
                  style={style}
              >
                  {title && <h2>{title}</h2>}
                  {content && <p>{content}</p>}
                  {buttons?.map((props, index) => (
                      <Button key={'toast-button-' + index} onClick={() => close()} {...props} />
                  ))}
              </div>,
              document.body
          )
        : null;
};
