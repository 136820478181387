import { mapRequestUserAndProfileToUser, mapUserAndProfileToUser } from '$redux/helpers';

const mapReviewer = ({
    final_voted,
    created,
    request_user_email,
    request_user_uuid,
    request_user_profile,
    ...reviewer
}) => ({
    ...mapUserAndProfileToUser(reviewer),
    requestUser: request_user_uuid
        ? mapRequestUserAndProfileToUser({
              request_user_email,
              request_user_uuid,
              request_user_profile
          })
        : null,
    created: created ? new Date(created) : null,
    final_voted
});

const mapFetchFileReviewersToUpdate = (reviewers, uuid) => ({
    fileReviews: {
        [uuid]: reviewers.map(mapReviewer)
    }
});

export default mapFetchFileReviewersToUpdate;
