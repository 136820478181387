import { CSSProperties, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import useWindowSize from '@he-novation/design-system/hooks/useWindowSize';

export enum WindowingDirection {
    Vertical,
    Horizontal
}

export type WindowingOptions = {
    itemHeight: number;
    itemWidth: number;
    itemsPerRow: number;
    minRows?: number;
    offset?: [number, number];
    direction?: WindowingDirection;
};

export type Windowing = {
    scrollContainerStyle?: CSSProperties;
    itemWrapperStyle?: CSSProperties;
    computeItemStyle: (index: number) => CSSProperties;
    visibleRange: [number, number];
};

const SCROLLBAR_HEIGHT = 30;

export function useWindowing(
    scrollContainer: MutableRefObject<HTMLElement | null>,
    w: WindowingOptions | undefined | null,
    items: number
): Windowing | null {
    const [windowWidth, windowHeight] = useWindowSize();
    const [visibleRange, setVisibleRange] = useState<[number, number]>([0, 0]);
    const totalRows = useMemo(() => (w ? Math.ceil(items / w.itemsPerRow) : 0), [items, w]);
    const direction = w?.direction || WindowingDirection.Vertical;

    useEffect(() => {
        if (!w || !scrollContainer.current) return;

        const onScroll = getOnScroll(
            totalRows,
            direction,
            scrollContainer.current,
            w,
            setVisibleRange
        );

        onScroll();
        scrollContainer.current.addEventListener('scroll', onScroll);
        return () => {
            scrollContainer.current?.removeEventListener('scroll', onScroll);
        };
    }, [w, totalRows, items, windowHeight, windowWidth]);

    const computeItemStyle = useCallback(
        (index: number): CSSProperties => {
            if (!w) return {};

            const itemRow = Math.floor(index / w.itemsPerRow);

            let top: number;
            let left: number;

            if (w.direction === WindowingDirection.Horizontal) {
                top = (index % w.itemsPerRow) * w.itemHeight + (w.offset?.[1] || 0);
                left = itemRow * w.itemWidth + (w.offset?.[0] || 0);
            } else {
                top = itemRow * w.itemHeight + (w.offset?.[1] || 0);
                left = (index % w.itemsPerRow) * w.itemWidth + (w.offset?.[0] || 0);
            }

            return {
                position: 'absolute',
                height: w.itemHeight,
                width: w.itemWidth,
                top,
                left
            };
        },
        [w]
    );

    if (!w || (typeof w.minRows !== 'undefined' && w.minRows > totalRows)) return null;

    return {
        scrollContainerStyle: {
            overflowY: direction === WindowingDirection.Vertical ? 'auto' : undefined,
            overflowX: direction === WindowingDirection.Horizontal ? 'auto' : undefined
        },
        itemWrapperStyle: {
            position: 'relative',
            minHeight:
                direction === WindowingDirection.Vertical
                    ? totalRows * w.itemHeight + SCROLLBAR_HEIGHT
                    : undefined,
            minWidth:
                direction === WindowingDirection.Horizontal ? totalRows * w.itemWidth : undefined
        },
        computeItemStyle,
        visibleRange
    };
}

function getOnScroll(
    totalRows: number,
    direction: WindowingDirection,
    scrollContainerElement: HTMLElement,
    w: WindowingOptions,
    setVisibleRange: (prevState: [number, number]) => void
): () => void {
    let offset: number;
    let itemSize: number;

    if (direction === WindowingDirection.Horizontal) {
        offset = scrollContainerElement.offsetWidth;
        itemSize = w.itemWidth;
    } else {
        offset = scrollContainerElement.offsetHeight;
        itemSize = w.itemHeight;
    }

    const visibleRows = Math.ceil(offset / itemSize);
    const visibleWindowSize = visibleRows * itemSize;

    const loadingWindowRows = Math.max(visibleRows * 2, w.minRows || 0);
    const loadingWindowSize = loadingWindowRows * itemSize;

    const totalSize = totalRows * itemSize;
    const unloadedSize = totalSize - loadingWindowSize;

    return () => {
        if (!scrollContainerElement) return;
        let scrollStart: number;

        if (direction === WindowingDirection.Horizontal) {
            scrollStart = scrollContainerElement.scrollLeft;
        } else {
            scrollStart = scrollContainerElement.scrollTop;
        }

        scrollStart -= visibleWindowSize;
        scrollStart = Math.max(0, Math.min(scrollStart, unloadedSize));

        const firstVisibleRow = Math.floor(scrollStart / itemSize);
        const firstVisible = firstVisibleRow * w.itemsPerRow;

        const lastVisibleRow = Math.min(firstVisibleRow + loadingWindowRows, totalRows - 1);
        const lastVisible = lastVisibleRow * w.itemsPerRow + w.itemsPerRow - 1;

        setVisibleRange([firstVisible, lastVisible]);
    };
}
