import './AvatarUser.scss';
import React, { CSSProperties } from 'react';
import Avatar from './Avatar';
import { getDisplayName } from '../../../utils/getDisplayName';
import { initialsFromEmail } from '../../../utils/initialsFromEmail';
import { Icon } from '../../graphics/Icon/Icon';
export { AvatarSize } from './Avatar';

export type AvatarUserProps = {
    [key: string]: any;
    user_uuid?: string;
    uuid?: string;
    email?: string;
    firstname?: string | null;
    lastname?: string | null;
    picture?: string | null;
    style?: CSSProperties;
    tooltip?: boolean;
    tooltipClassName?: string;
    getTooltipContent?: (user: any) => string;
    onClick?: (e: any) => void;
};

export class AvatarUser extends React.Component<AvatarUserProps> {
    public static propsToTooltip = (user: any) => getDisplayName(user, true);
    render() {
        const {
            user_uuid,
            uuid,
            email,
            firstname,
            lastname,
            picture,
            onClick,
            style,
            tooltip,
            tooltipClassName,
            getTooltipContent,
            ...avatarProps
        } = this.props;

        const toTooltip = getTooltipContent || AvatarUser.propsToTooltip;

        return (
            <Avatar
                tooltip={tooltip && toTooltip({ email, firstname, lastname })}
                picture={picture}
                style={style}
                tooltipClassName={tooltipClassName}
                onClick={
                    typeof onClick === 'function'
                        ? () => onClick({ user_uuid, uuid, email, firstname, lastname, picture })
                        : undefined
                }
                pictureFallback={
                    firstname === 'LOG_ANONYMOUS' ? (
                        <div className="user-fallback">
                            <Icon icon={'user'} />
                        </div>
                    ) : null
                }
                initials={
                    firstname && lastname ? firstname[0] + lastname[0] : initialsFromEmail(email)
                }
                {...avatarProps}
            />
        );
    }
}
