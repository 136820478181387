import { useMemo, useState } from 'react';
import { ROLES, TEAM_ADMIN, TEAM_CAST } from '@he-novation/config/constants/projects.constants';
import { Member } from '@he-novation/config/types/payloads/team.payload';
import { TeamMember, TeamWithMembers } from '@he-novation/config/types/team.types';
import {
    asyncProjectMembersInvite,
    asyncProjectMemberUpdate,
    asyncProjectTeamsFetch
} from '@he-novation/front-shared/async/project.async';
import update from 'immutability-helper';
import { useAtom } from 'jotai/index';

import { projectTeamsAtom, projectTeamsProjectUuidAtom } from '$atoms/project-atoms';

let fetchingTeamsForUuid: string | null = null;

export function useProjectTeams() {
    const [projectTeams, setProjectTeams] = useAtom(projectTeamsAtom);
    const [projectTeamsProjectUuid, setProjectTeamsProjectUuid] = useAtom(
        projectTeamsProjectUuidAtom
    );
    const [loading, setLoading] = useState(false);

    const filteredTeams = useMemo(() => {
        return projectTeams.filter((t) => t.name !== TEAM_CAST);
    }, [projectTeams]);

    const accessibleTeams = useMemo(() => {
        const ownTeam = projectTeams.find((t) => t.ownTeam);
        if (!ownTeam) return [];
        if (ownTeam.name === TEAM_ADMIN) return projectTeams;
        const t = [ownTeam];
        if (ownTeam.castTeamAccess) t.push(projectTeams.find((t) => t.name === TEAM_CAST)!);
        return t;
    }, [projectTeams]);

    const ownTeam = useMemo(() => projectTeams.find((t) => t.ownTeam), [projectTeams]);

    return {
        loading,
        teams: projectTeams,
        ownTeam,
        filteredTeams,
        accessibleTeams,

        async fetchProjectTeams(projectUuid: string) {
            if (projectUuid === projectTeamsProjectUuid || fetchingTeamsForUuid === projectUuid) {
                return;
            }
            fetchingTeamsForUuid = projectUuid;
            setProjectTeams([]);
            setProjectTeamsProjectUuid(projectUuid);
            setLoading(true);
            asyncProjectTeamsFetch(projectUuid).then((teams) => {
                setLoading(false);
                setProjectTeams(teams);
            });
        },
        getTeams(options: { cast?: boolean; admin?: boolean } = {}) {
            if (options.cast && options.admin) return projectTeams;

            return projectTeams.filter((t) => {
                if (options.cast && t.name === TEAM_CAST) return false;
                return !(options.admin && t.name === TEAM_ADMIN);
            });
        },
        async updateMember(
            projectUuid: string,
            userUuid: string,
            data: { teamUuid: string; role: ROLES; download: boolean }
        ) {
            await asyncProjectMemberUpdate(projectUuid, userUuid, data);
            setProjectTeams(
                updateTeamMember(projectTeams, {
                    uuid: userUuid,
                    ...data
                })
            );
        },

        async inviteMember(
            projectUuid: string,
            teamName: string,
            members: Member[],
            castTeamAccess?: boolean,
            message?: string,
            teamColor?: string
        ) {
            const team = await asyncProjectMembersInvite(
                projectUuid,
                teamName,
                members,
                castTeamAccess,
                message,
                teamColor
            );

            setProjectTeams((projectTeams) => {
                const teamIndex = projectTeams.findIndex((t) => t.name === teamName);
                if (teamIndex > -1) {
                    return update(projectTeams, {
                        [teamIndex]: {
                            $merge: team
                        }
                    });
                } else {
                    return [...projectTeams, team];
                }
            });
        },
        handleUpdatedMember: (data: {
            teamUuid?: string;
            uuid: string;
            projectRole?: ROLES;
            download?: boolean;
        }) => {
            setProjectTeams((projectTeams) => updateTeamMember(projectTeams, data));
        },
        handleDeletedMember(uuid: string) {
            setProjectTeams((projectTeams) => deleteTeamMember(projectTeams, uuid));
        },
        handleTeamCreated(team: TeamWithMembers) {
            setProjectTeams((projectTeams) => {
                if (projectTeams.find((t) => t.uuid === team.uuid)) {
                    return projectTeams;
                }
                return [...projectTeams, team];
            });
        },
        handleTeamDeleted(teamUuid: string) {
            setProjectTeams((projectTeams) => projectTeams.filter((t) => t.uuid !== teamUuid));
        },
        handleTeamUpdate(team: TeamWithMembers) {
            const teamIndex = projectTeams.findIndex(({ uuid }) => uuid === team.uuid);
            if (teamIndex === undefined || teamIndex === -1) return;
            setProjectTeams((projectTeams) =>
                update(projectTeams, {
                    [teamIndex]: {
                        $merge: team
                    }
                })
            );
        }
    };
}

function findTeamAndMemberIndex(projectTeams: TeamWithMembers[], uuid: string) {
    let memberIndex = -1;
    const teamIndex = projectTeams.findIndex((t) => {
        memberIndex = t.members.findIndex((m) => m.uuid === uuid);
        return memberIndex > -1;
    });
    return [teamIndex, memberIndex];
}

function updateTeamMember(
    projectTeams: TeamWithMembers[],
    data: {
        teamUuid?: string;
        uuid: string;
        projectRole?: ROLES;
        download?: boolean;
    }
): TeamWithMembers[] {
    if (!projectTeams) return projectTeams;
    const [teamIndex, memberIndex] = findTeamAndMemberIndex(projectTeams, data.uuid);
    if (teamIndex === -1 || memberIndex === -1) return projectTeams;
    const sourceTeam = projectTeams[teamIndex];
    const sourceMember = sourceTeam.members[memberIndex];
    if (data.teamUuid && data.teamUuid !== sourceTeam.uuid) {
        const newTeamIndex = projectTeams.findIndex(({ uuid }) => uuid === data.teamUuid);

        return update(projectTeams, {
            [teamIndex]: {
                members: {
                    $splice: [[memberIndex, 1]]
                }
            },
            [newTeamIndex]: {
                members: {
                    $push: [
                        {
                            ...sourceMember,
                            role: data.projectRole || sourceMember.role,
                            canDownload: data.download
                        }
                    ]
                }
            }
        });
    } else {
        const _update: Partial<TeamMember> = {};
        if (data.projectRole) _update.role = data.projectRole;
        if (typeof data.download !== 'undefined') _update.canDownload = data.download;
        return update(projectTeams, {
            [teamIndex]: {
                members: {
                    [memberIndex]: {
                        $merge: _update
                    }
                }
            }
        });
    }
}

function deleteTeamMember(projectTeams: TeamWithMembers[], uuid: string): TeamWithMembers[] {
    if (!projectTeams) return projectTeams;
    const [teamIndex, memberIndex] = findTeamAndMemberIndex(projectTeams, uuid);
    if (teamIndex === -1 || memberIndex === -1) return projectTeams;

    return update(projectTeams, {
        [teamIndex]: {
            members: {
                $splice: [[memberIndex, 1]]
            }
        }
    });
}
