const path = '/hpi/';

export const paths = {
    cast: {
        root: `${path}casts`,
        single: `${path}cast`,
        singleExtension: `${path}cast-extension`,
        specific: `${path}cast/:castUid`,
        image: `${path}cast/:castUid/image`,
        share: `${path}cast/share`,
        fromFolder: `${path}cast/folder/:folderUuid/triggers`
    },

    castTrigger: {
        single: `${path}cast/:castUid/trigger`,
        specific: `${path}cast/:castUid/trigger/:folderCastTriggerUid`
    },
    castPrivate: {
        specific: `${path}cast/:castUid/view/:castFolderUuid?`
    },
    castPublic: {
        specific: `${path}p/cast/:castUid/view/:castFolderUuid?`,
        views: `${path}p/cast/:castUid/views`,
        breadcrumb: `${path}p/cast/:castUid/folder-breadcrumb/:castFolderUuid`,
        file: `${path}p/cast/:castUid/file/:castFileUuid`,
        download: `${path}p/cast/:castUid/download/:castFolderUuid?`,
        size: `${path}p/cast/:castUid/size`
    },
    castFolders: {
        root: `${path}cast/:castUid/folders`,
        single: `${path}cast/:castUid/folder`,
        specific: `${path}cast/:castUid/folder/:castFolderUuid?`
    },

    castFiles: {
        root: `${path}cast/:castUid/files`,
        single: `${path}cast/:castUid/file`,
        specific: `${path}cast/:castUid/file/:castFileUuid`,
        casts: `${path}cast/file/:fileUuid`
    },
    castNotes: {
        root: `${path}cast/:castUid/f/:castFileUuid/:fileVersion/notes`,
        single: `${path}cast/note`,
        comment: `${path}cast/note/:noteUuid/comment`
    },
    chat: {
        root: `${path}chat`,
        single: `${path}chat/:roomUuid`,
        invite: `${path}chat/:roomUuid/invite`
    },
    client: {
        root: `${path}client`,
        multiple: `${path}clients`,
        specific: `${path}clients/:clientUuid`,
        storage: `${path}client/storage`,
        account: `${path}client/account`,
        usersStorage: `${path}client/users-storage`,
        oidcLink: `${path}client/oidc/link`,
        oidc: `${path}client/oidc`,
        styles: `${path}client-styles`,
        defaultCastStyle: `${path}client/default-cast-style`,
        css: '/css/v2/:clientName.css',
        marketingStyles: '/css/v2/marketing',
        users: `${path}client/users`,
        guests: `${path}client/guests`,
        terms: `${path}client/:clientName/terms/:locale`,
        stats: `${path}client/stats`,
        profile: `${path}client/profile`,
        projects: `${path}client/projects`,
        finalFiles: `${path}client/files/final`,
        user: `${path}client/user`,
        userSpecific: `${path}client/user/:userUuid`,
        userSpecificPassword: `${path}client/user/:userUuid/password`,
        userSpecificRemove: `${path}client/user/:userUuid/remove`,
        userTransfer: `${path}client/user/:userUuid/transferTo/:userDstUuid`
    },
    clientEventLabels: {
        multiple: `${path}client-event-labels`,
        single: `${path}client-event-label`,
        specific: `${path}client-event-label/:uid`,
        associationSingle: `${path}client-event-label/:uid/associate/:associationUid`
    },
    clientContactLabels: {
        multiple: `${path}client-contact-labels`,
        single: `${path}client-contact-label`,
        specific: `${path}client-contact-label/:uid`,
        associationSingle: `${path}client-contact-label/:uid/associate/:associationUid`
    },
    clientItemLabels: {
        multiple: `${path}client-item-labels`,
        single: `${path}client-item-label`,
        specific: `${path}client-item-label/:uid`,
        associationSingle: `${path}client-item-label/:uid/associate/:associationUid`
    },
    contact: {
        root: `${path}contacts`,
        single: `${path}contact`,
        specific: `${path}contact/:contactUuid`,
        group: `${path}contacts/group/:groupUuid`
    },
    contactGroup: {
        multiple: `${path}contact-groups`,
        multipleContacts: `${path}contact-groups/contacts`,
        single: `${path}contact-group`,
        specific: `${path}contact-group/:contactGroupUuid`
    },
    contactGroupContact: {
        single: `${path}contact-group/:contactGroupUuid/contact`
    },
    email: {
        fileNew: `${path}emails/fileNew`,
        folderGrantAccess: `${path}emails/folderGrantAccess`
    },
    folder: {
        root: `${path}folder`,
        specific: `${path}folder/:folderUuid`,
        rootContent: `${path}folder-content`,
        notifications: `${path}folder/:folderUuid/notifications`,
        content: `${path}folder-content/:folderUuid?`,
        members: `${path}folder-members/:folderUuid`,
        shared: `${path}shared-folder`,
        teams: `${path}folder/:folderUuid/teams`,
        publicInfos: `${path}folder/public/:folderUuid/infos`,
        path: `${path}folder/:folderUuid/path`,
        invite: `${path}folder/:folderUuid/invite`,
        admittance: `${path}folder/:folderUuid/admittance`,
        move: `${path}folder/:sourceFolderUuid/move/:targetFolderUuid`,
        sharedWith: `${path}user/:userUuid/shared-folders`,
        recursive: `${path}folder/:folderUuid/recursive`
    },

    google: {
        calendarsSpecific: `${path}google/calendars/:googleUserId`
    },
    item: {
        multiple: `${path}items/client/:clientUuid?`,
        single: `${path}item`,
        specific: `${path}item/:itemUuid`,
        specificPicture: `${path}item/:itemUuid/picture`,
        specificEvents: `${path}item/:itemUuid/events`,
        multipleEvents: `${path}items/events/:clientUuid?`,
        multipleProject: `${path}items/project/:projectUuid`,
        all: `${path}items/withProjectItems`,
        kitMultiple: `${path}item-kits`,
        kitMultipleProject: `${path}item-kits/project/:projectUuid`,
        kitSingle: `${path}item-kit`,
        kitSpecific: `${path}item-kit/:itemKitUuid`,
        kitItem: `${path}item-kits/:itemKitUuid/:itemUuid`
    },
    tasks: {
        root: `${path}tasks`,
        multipleStatus: `${path}tasks/status`,
        single: `${path}tasks/:taskUuid`,
        assets: `${path}task-assets`
    },
    projects: {
        single: `${path}projects/:projectUuid`,
        folder: `${path}projects/:projectUuid/folder`,
        tasks: `${path}projects/:projectUuid/tasks`,
        getTeamsByProject: `${path}projects/:projectUuid/teams`,
        getProjectTasksByTeam: `${path}projects/:projectId/teams/:teamId/tasks`,
        updateCurrentUserMetadata: `${path}projects/:projectUuid/user/metadata`,
        member: `${path}projects/:projectUuid/user/:userUuid`,
        getAvailableCompanies: `${path}projects/companies`,
        taskAssets: `${path}projects/:projectUuid/taskAssets`,
        updateProjectStatus: `${path}projects/:projectUuid/status`,
        updateFromFolder: `${path}projects/:projectId/updateFromFolder`,
        updateFolderProjectId: `${path}projects/:projectUuid/folder/:folderUuid`,
        clientEventLabels: `${path}projects/:projectUuid/client-event-labels`,
        stats: `${path}projects/:projectUuid/stats`
    },
    outlook: {
        calendarsSpecific: `${path}outlook/calendars/:homeAccountId`,
        calendarSpecific: `${path}outlook/calendar/:homeAccountId`
    },
    teams: {
        inviteToTeam: `${path}teams/:teamName`,
        single: `${path}teams/:teamUuid`,
        updateStatus: `${path}teams/:teamUuid/status`
    },
    users: {
        root: `${path}user`,
        account: `${path}user/account`,
        login: `${path}user/login`,
        profile: `${path}user/profile`,
        appInfos: `${path}user/infos`,
        licenses: `${path}user/licenses`
    },
    rpc: {
        thumbnailCast: `${path}rpc/thumbnailCast/:encodingOrJob/:status/:uuid`
    },
    wording: {
        single: `${path}wording`,
        specific: `${path}wording/:identifier`
    },
    wordingList: {
        multiple: `${path}wording-lists`,
        single: `${path}wording-list`,
        specific: `${path}wording-list/:listName`
    },
    wordingListEntry: {
        single: `${path}wording-list/:listName/entry`
    }
};

export const archivePaths = {
    specific: `${path}archive/:archiveUuid`,
    folder: `${path}archive/:folderUuid`,
    cast: `${path}cast-archive/:castUid/:castFolderUuid?`,
    files: `${path}archive/files`
};

export const assetPaths = {
    single: `${path}asset/:assetUuid`,
    status: `${path}asset/:assetUuid/status`,
    upload: `${path}asset/:assetUuid/upload`,
    sign: `${path}asset/:assetUuid/sign`,
    test: `${path}asset/uploads/test`,
    migrateFolder: `${path}assets/migrate/folder`,
    buckets: '/buckets'
};

export const authPaths = {
    login: `/auth/login`,
    logout: `/auth/logout`,
    password: `/auth/password`,
    passwordReset: `/auth/password-reset`,
    passwordToken: `/auth/password-token`,
    twoFactor: `/auth/two-factor`
};

export const castFolderContentPaths = {
    specific: `${path}cast/:castUid/folder-content/:castFolderUuid?`
};

export const clientPaths = {
    preferences: `${path}client/preferences`,
    domains: `${path}client/domains`,
    domainSpecific: `${path}client/domain/:clientDomainUuid`
};
export const clientCastLabelPaths = {
    multiple: `${path}client-cast-labels`,
    single: `${path}client-cast-label`,
    specific: `${path}client-cast-label/:uid`,
    associationSingle: `${path}client-cast-label/:uid/associate/:associationUid`
};
export const companyPaths = {
    root: `${path}companies`,
    specific: `${path}companies/:companyUuid`,
    specificPicture: `${path}companies/:companyUuid/picture`
};

export const eventPaths = {
    root: `${path}events`,
    specific: `${path}events/:eventUuid`,
    date: `${path}events/:eventUuid/date`,
    user: `${path}events/:eventUuid/user`,
    email: `${path}events/:eventUuid/email`,
    project: `${path}events/project/:projectUuid`,
    reminders: `${path}events/:eventUuid/reminder`,
    reminderSpecific: `${path}events/:eventUuid/reminder/:reminderUuid`
};

export const stripePaths = {
    config: `${path}stripe/config`
};
export const localePaths = {
    languageCountrySpecific: `${path}locales/language-country/:locale`
};

export const userFilePaths = {
    multiple: `${path}user-files`,
    single: `${path}user-file`,
    specific: `${path}user-file/:userFileUid`
};

export const userNotificationsPaths = {
    userNotifications: `${path}user-notifications/:clientName`,
    projectNotifications: `${path}user-notifications/:clientName/project/:projectUuid`
};

export const userPaths = {
    oidcLogin: `/oidc/:provider/login`,
    translationCredits: `${path}user/translation-credits`,
    plugins: `${path}user/plugins`,
    pluginConfigurations: `${path}user/plugin-configurations`,
    preferences: `${path}user/preferences`,
    storage: `${path}user/storage`,
    license: `${path}user/license`,
    verify: `${path}user/verify`
};

export const pluginPaths = {
    single: `${path}/plugin`,
    specific: `${path}plugin/:pluginUuid`,
    auth: `${path}plugin/auth/:type`,
    export: `${path}plugin/export/:plugin`
};

export const subtitlesPaths = {
    single: `${path}subtitle`,
    upload: `${path}subtitle-upload`,
    generatedSingle: `${path}subtitle-generated`,
    specific: `${path}subtitle/:subtitleUuid`,
    link: `${path}subtitle/:subtitleUuid/link`,
    entrySingle: `${path}subtitle/:subtitleUuid/entry`,
    entrySpecific: `${path}subtitle/:subtitleUuid/entry/:entryUuid`,
    transcode: `${path}subtitle/:subtitleUuid/transcode`,
    spotlLanguages: `${path}subtitles/spotl-languages`,
    burn: `${path}subtitle/:subtitleUuid/burn/:fileUuid/:fileVersion`
};

export const transcoderPaths = {
    transcoders: '/transcoders',
    transcoderSpecific: '/transcoders/:transcoderUuid',
    availableInstances: '/transcoder-instances',
    unassignedTasks: '/transcoder-unassigned-tasks',
    pid: '/transcoder-pid'
};

export const logPaths = {
    root: `${path}logs`,
    old: `${path}logs/old`
};

export const notePaths = {
    file: `${path}notes/file/:fileUuid/:fileVersion`,
    root: `${path}notes`,
    single: `${path}notes/:noteUuid`,
    comment: `${path}notes/:noteUuid/comment`,
    commentSingle: `${path}notes/:noteUuid/comment/:commentUuid`,
    commentAttachment: `${path}notes/:noteUuid/comment/:commentUuid/attachment`,
    avidSummary: `${path}notes/file/:fileUuid/:fileVersion/avid-summary`
};

export const castNotePaths = {
    root: `${path}cast/:castUid/f/:castFileUuid/:fileVersion/notes`,
    single: `${path}cast/note`,
    comment: `${path}cast/note/:noteUuid/comment`
};

export const filePaths = {
    single: `${path}file`,
    specific: `${path}file/:fileUuid`,
    versionSpecific: `${path}file/:fileUuid/:fileVersion`,
    copy: `${path}file/:fileUuid/:fileVersion/copy`,
    move: `${path}file/:fileUuid/move/:folderUuid`,
    subtitles: `${path}file/:fileUuid/:fileVersion/subtitles`,
    versions: `${path}file-versions/:fileUuid`,
    version: `${path}file/:fileUuid/:fileVersion/version`,
    assets: `${path}file/:fileUuid/:fileVersion/assets`,
    final: `${path}file/:fileUuid/:fileVersion/final`,
    status: `${path}file/:fileUuid/:fileVersion/status`,
    tasks: `${path}file/:fileUuid/:fileVersion/tasks`,
    encode: `${path}file/:fileUuid/:fileVersion/encode`,
    restore: `${path}file/:fileUuid/:fileVersion/restore`,
    assetQuality: `${path}file/:fileUuid/:fileVersion/asset/:quality`,
    hlsKey: `${path}file/:fileUuid/:fileVersion/hls-key`,
    mimeType: `${path}file-mimetype`,
    bulkDelete: `${path}files-delete`
};

export const searchPaths = {
    root: `${path}search`
};

export const folderPaths = {
    content: `${path}folder-content/:folderUuid?`,
    copyTree: `${path}folder/copy-tree`,
    leave: `${path}folder/:folderUuid/leave`,
    membersSpecific: `${path}folder-members/:folderUuid/:userUuid`,
    notifications: `${path}folder/:folderUuid/notifications`,
    plugins: `${path}folder/:folderUuid/plugins`,
    restore: `${path}folder/:folderUuid/restore`,
    size: `${path}folder/:folderUuid/size`,
    specific: `${path}folder/:folderUuid`,
    trash: `${path}trash`,
    bulkDelete: `${path}folders-delete`
};

export const projectPaths = {
    multiple: `${path}projects`,
    single: `${path}project`,
    specific: `${path}projects/:projectUuid`,
    css: `${path}projects/:projectUuid/css`,
    fromFolder: `${path}project-from-folder`
};

export const webhookPaths = {
    spotl: `${path}webhooks/spotl`
};

export const publicApiV1Paths = {
    asset: {
        upload: `/public/v1/asset/:assetUuid/upload`
    },
    auth: {
        user: '/public/v1/auth/user'
    },
    cast: {
        root: '/public/v1/casts',
        single: '/public/v1/cast',
        specific: '/public/v1/cast/:castUid',
        fromFolder: '/public/v1/cast/folder/:folderUuid/triggers',
        share: '/public/v1/cast/share'
    },
    castFiles: {
        single: '/public/v1/cast/:castUid/file',
        specific: '/public/v1/cast/:castUid/file/:castFileUuid',
        casts: '/public/v1/cast/file/:fileUuid'
    },
    castFolders: {
        single: '/public/v1/cast/:castUid/folder',
        specific: '/public/v1/cast/:castUid/folder/:castFolderUuid'
    },
    contact: {
        byEmail: '/public/v1/contacts/by-email'
    },
    contactGroup: {
        specific: '/public/v1/contact-group/:groupUuid'
    },
    event: {
        single: '/public/v1/event'
    },
    file: {
        single: '/public/v1/file',
        specific: '/public/v1/file/:fileUuid',
        versions: `/public/v1/file-versions/:fileUuid`,
        specificVersion: '/public/v1/file/:fileUuid/v/:fileVersion',
        delete: '/public/v1/file/:fileUuid/delete',
        subtitles: '/public/v1/file/:fileUuid/v/:fileVersion/subtitles'
    },
    folder: {
        single: '/public/v1/folder',
        specific: '/public/v1/folder/:folderUuid',
        invite: '/public/v1/folder/:folderUuid/invite',
        public: '/public/v1/folder/:folderUuid/public',
        teams: '/public/v1/folder/:folderUuid/teams'
    },
    folderContent: {
        specific: '/public/v1/folder-content/:folderUuid'
    },
    note: {
        multiple: '/public/v1/file/:fileUuid/v/:fileVersion/notes',
        single: '/public/v1/file/:fileUuid/v/:fileVersion/note',
        comment: '/public/v1/note/:noteUuid/comment'
    },
    projects: {
        multiple: '/public/v1/projects',
        specific: '/public/v1/projects/:projectUuid',
        status: '/public/v1/projects/:projectUuid/status',
        member: `/public/v1/projects/:projectUuid/users/:userUuid`,
        teams: '/public/v1/projects/:projectUuid/teams'
    },
    search: {
        root: '/public/v1/search'
    },
    subtitle: {
        single: '/public/v1/subtitle',
        specific: '/public/v1/subtitle/:subtitleUuid'
    },
    task: {
        single: '/public/v1/task'
    },
    teams: {
        inviteToTeam: `/public/v1/teams/:teamName`
    },
    versions: {
        multiple: '/public/v1/versions'
    },

    ingest: {
        ingest: '/public/v1/ingest',
        s3: '/public/v1/ingest/s3'
    }
};

export const pathWithParams = (
    p: string,
    params: Record<string, string | number | undefined> = {},
    query: Record<string, string | number | undefined | null | string[]> = {}
) => {
    if (!params) params = {};
    let uri = '';
    const paramKeys = Object.keys(params);

    uri += paramKeys.reduce(
        (acc, curr) =>
            Array.isArray(params[curr])
                ? curr
                : curr === '*'
                ? acc.replace('*', params[curr] as string)
                : acc.replace(
                      new RegExp(`:${curr}\\??|\\?${curr}`, 'g'),
                      (typeof params[curr] !== 'undefined' ? params[curr] : '') as string
                  ),
        p
    );

    if (query) {
        const queryKeys = Object.keys(query);
        if (queryKeys.length) {
            uri += queryKeys.reduce((acc, curr) => {
                if (typeof query[curr] === 'undefined') return acc;
                if (Array.isArray(query[curr])) {
                    return (
                        acc +
                        (query[curr] as string[]).reduce(
                            (acc2, curr2: string) =>
                                `${curr}=${encodeURIComponent(curr2 as string)}&${acc2}`,
                            ''
                        )
                    );
                }
                return (
                    acc +
                    `${curr}=${encodeURIComponent(
                        (typeof query[curr] !== 'string'
                            ? JSON.stringify(query[curr])
                            : query[curr]) as string
                    )}&`
                );
            }, '?');
        }
    }
    return uri;
};

export default paths;
