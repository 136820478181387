import React, { SyntheticEvent } from 'react';
import cn from 'classnames';
import { Tabs, TabsMode } from '../../widgets/Tabs/Tabs';
import { Theme } from '../../../enums';
import { IconProp } from '../../graphics/Icon/Icon';

export enum CustomControlType {
    Tab = 'tab'
}

export type CustomControl<T extends CustomControlType = any, V = any> = {
    type: T;
    value?: V;
    values: { label?: string; icon?: IconProp; value?: V }[];
    onClick: T extends CustomControlType.Tab ? (e: SyntheticEvent, value: V) => void : undefined;
};

type EditorCustomControlsProps = {
    controls: CustomControl[];
    theme: Theme;
};

export const EditorCustomControls: React.FC<EditorCustomControlsProps> = ({ controls, theme }) => {
    return (
        <>
            {controls.map((c, i) => {
                switch (c.type) {
                    case CustomControlType.Tab:
                        return (
                            <Tabs
                                key={i}
                                className={cn('editor-custom-control', 'cc-tab')}
                                activeTab={c.value}
                                tabs={c.values.map((v) => ({
                                    value: v.value,
                                    label: v.label,
                                    icon: v.icon,
                                    onClick: (e) => c.onClick?.(e, v.value)
                                }))}
                                mode={TabsMode.ButtonsOnly}
                            />
                        );
                }
            })}
        </>
    );
};
