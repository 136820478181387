import './App.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE, PROJECT_ROUTES } from '@he-novation/config/paths/herawFrontUris';
import { HubSpotChat } from '@he-novation/front-shared/components/HubSpotChatScript';
import { useAtomValue } from 'jotai';
import routes from '../../routes';

import { viewErrorAtom } from '$atoms/error-atoms';
import { AbsoluteMenu } from '$components/AbsoluteMenu/AbsoluteMenu';
import { HeaderWrapper } from '$components/layout/Header/HeaderWrapper';
import { Main } from '$components/layout/Main/Main';
import { MainMenu } from '$components/layout/MainMenu/MainMenu';
import ToastContainer from '$components/layout/ToastContainer';
import Modal from '$components/modal/Modal/Modal';
import { PlaylistWrapper } from '$components/Playlist/PlaylistWrapper';
import Router from '$components/router/Router';
import { SidePanel } from '$components/SidePanel/SidePanel';
import { inIframe } from '$helpers/inIframe';
import { useFeedbackModal } from '$hooks/useFeedbackModal';
import { useNotifications } from '$hooks/useNotifications';
import { useTranslate } from '$hooks/useTranslate';
import { resetContent } from '$redux/content/contentActions';
import { routeSelector } from '$redux/route/routeSelectors';
import { getFrontRoutesFromLocation } from '$views/App/getFrontRoutesFromLocation';

type AppProps = {
    isTeamsApp?: boolean;
};
const getClassName = (url: string) => {
    if (url.startsWith('/cast/')) return 'is-cast';
    return 'is-app';
};

export function App(props: AppProps) {
    const dispatch = useDispatch();

    const { t } = useTranslate();
    const { openFeedbackModal } = useFeedbackModal();

    const isApp = !/^\/cast\/|^\/summary(_avid)?\//.test(window.location.pathname);

    const viewError = useAtomValue(viewErrorAtom);
    const { configRoute, route } = useSelector(routeSelector);

    useEffect(() => {
        if (inIframe() || props.isTeamsApp) {
            document.body.classList.add('msteams-iframe');
        }
        if (document.referrer.endsWith('/transfer'))
            setTimeout(
                () =>
                    openFeedbackModal(
                        t(
                            'misc.Your files are being processed. You and your guests will be notified by e-mail.'
                        )
                    ),
                500
            );

        document.getElementById('app')!.classList.add(getClassName(window.location.pathname));
        document
            .getElementById('app')!
            .classList.toggle('no-menu', configRoute === FILE && !viewError);
    }, []);

    useEffect(() => {
        document
            .getElementById('app')!
            .classList.toggle('project-styles', PROJECT_ROUTES.includes(configRoute));
        if (viewError && configRoute === FILE) {
            document.getElementById('app')!.classList.toggle('no-menu', false);
        } else {
            if (configRoute === FILE) {
                document.getElementById('app')!.classList.toggle('no-menu', true);
            } else {
                document.getElementById('app')!.classList.toggle('no-menu', false);
            }
        }
    }, [viewError, configRoute]);

    useNotifications();

    const shouldLoadHubspotChatScript = /prod|production/.test(process.env.NODE_ENV as string);

    const content = !isApp ? (
        <Main>
            <Main.Content>
                <Router
                    genericErrorMessage={t('common.Sorry, something went wrong')}
                    routes={getFrontRoutesFromLocation()}
                    onUpdate={(prevUrl: string, url: string) =>
                        dispatch(resetContent(prevUrl, url))
                    }
                />
                <PlaylistWrapper />
            </Main.Content>
            <SidePanel />
        </Main>
    ) : (
        <>
            {(configRoute !== FILE || viewError) && <MainMenu />}
            <HeaderWrapper />
            <Main>
                <Main.Content>
                    <Router
                        genericErrorMessage={t('common.Sorry, something went wrong')}
                        routes={routes}
                        onUpdate={(prevUrl: string, url: string) =>
                            dispatch(resetContent(prevUrl, url))
                        }
                    />
                    <PlaylistWrapper />
                </Main.Content>
                <SidePanel />
                <AbsoluteMenu />
            </Main>
            {shouldLoadHubspotChatScript && <HubSpotChat />}
        </>
    );

    if (
        window.location.pathname.startsWith('/summary') ||
        route.startsWith('summary') ||
        route.startsWith('cast')
    )
        return content;

    const appContent = (
        <>
            {content}
            <Modal />
            <ToastContainer />
        </>
    );
    return process.env.REACT_STRICT_MODE_DISABLED ? (
        appContent
    ) : (
        <React.StrictMode>{appContent}</React.StrictMode>
    );
}

export default App;
