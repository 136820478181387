import { signInLink } from '@he-novation/config/paths/herawLoginPaths';
import { AuthPasswordUpdatePayload } from '@he-novation/config/types/payloads/auth.payload';
import {
    PluginConfigurationCreationSchema,
    PluginConfigurationSchema
} from '@he-novation/config/types/payloads/plugin.payload';
import {
    UserInfoSchema,
    UserPreferencesSchema
} from '@he-novation/config/types/payloads/user.payload';
import { PluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { UserAppInfos, type UserLicense, UserStorage } from '@he-novation/config/types/user.types';
import {
    asyncPluginConfigurationCreate,
    asyncPluginConfigurationDelete,
    asyncPluginConfigurationUpdate
} from '@he-novation/front-shared/async/plugin.async';
import {
    fetchAccount as _fetchAccount,
    fetchPlugins as _fetchPlugins,
    fetchPluginsEnabled,
    fetchUserAppInfos as _fetchUser,
    updatePassword as _updatePassword,
    updateProfile as _updateProfile,
    updateUserPreferences as _updateUserPreferences
} from '@he-novation/front-shared/async/user.async';
import { Dispatch } from 'redux';
import { v4 as uuidV4 } from 'uuid';
import asyncAction, { asyncActionSuccess } from '../../helpers/asyncAction';

export const USER = 'USER';

export const ADD_PLUGIN_CONFIGURATION = `${USER}/ADD_PLUGIN_CONFIGURATION`;
export const DELETE_PLUGIN_CONFIGURATION = `${USER}/DELETE_PLUGIN_CONFIGURATION`;
export const DISABLE_TOTP = `${USER}/DISABLE_TOTP`;
export const EDIT_PLUGIN_CONFIGURATION = `${USER}/EDIT_PLUGIN_CONFIGURATION`;
export const PLUGIN_MERGE_PROPERTIES = `${USER}/PLUGIN_MERGE_PROPERTIES`;
export const FETCH = `${USER}/FETCH`;
export const FETCH_ACCOUNT = `${USER}/FETCH_ACCOUNT`;
export const FETCH_PLUGINS_ACCESS = `${USER}/FETCH_PLUGINS_ACCESS`;
export const FETCH_STORAGE_LICENSES = `${USER}/FETCH_STORAGE_LICENSES`;
export const FETCH_STORAGE_TOTAL = `${USER}/FETCH_STORAGE_TOTAL`;
export const FETCH_STORAGE_USED = `${USER}/FETCH_STORAGE_USED`;
export const REQUEST_TOKEN = `${USER}REQUEST_TOKEN`;
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const UPDATE_PROFILE = `${USER}UPDATE_PROFILE`;
export const UPDATE_TOTP = `${USER}/UPDATE_TOTP`;

export const fetchUser =
    (cb?: (user: any) => void, onError?: (error: Error) => void) => async (dispatch) => {
        dispatch({ type: FETCH });
        try {
            const user = await _fetchUser();
            dispatch({ type: asyncActionSuccess(FETCH), user });
            if (typeof cb === 'function') cb(user);
        } catch (e) {
            onError?.(e);
        }
    };

export const updatePassword =
    (body: AuthPasswordUpdatePayload, cb: (e?: any) => void) => async (dispatch) => {
        dispatch({ type: UPDATE_PASSWORD });
        try {
            await _updatePassword(body);
            dispatch({ type: asyncActionSuccess(UPDATE_PASSWORD) });
            cb?.();
        } catch (e) {
            cb?.(e);
        }
    };

export const fetchPlugins =
    (cb?: ({ plugins, pluginsEnabled }: { plugins: string[]; pluginsEnabled: any }) => void) =>
    async (dispatch) => {
        dispatch({ type: FETCH_PLUGINS_ACCESS });
        const [plugins, pluginsEnabled] = await Promise.all([
            _fetchPlugins(),
            fetchPluginsEnabled()
        ]);
        dispatch({ type: asyncActionSuccess(FETCH_PLUGINS_ACCESS), plugins, pluginsEnabled });
        cb?.({ plugins, pluginsEnabled });
    };

export const fetchAccount =
    (cb?: (account: any) => void, onError?: (e: Error, redirectToLogin: () => void) => void) =>
    async (dispatch) => {
        dispatch({ type: FETCH_ACCOUNT });
        try {
            const account = await _fetchAccount();
            dispatch({ type: asyncActionSuccess(FETCH_ACCOUNT), account });
            if (typeof cb === 'function') cb(account);
        } catch (e) {
            console.error(e);
            const redirectToLogin = () => {
                window.location.href = signInLink('fr', {
                    redirect: window.location.pathname.replace('?', '&')
                });
            };
            if (!onError) {
                redirectToLogin();
            } else {
                onError(e, redirectToLogin);
            }
        }
    };

export const requestToken = () =>
    asyncAction(REQUEST_TOKEN, {
        method: 'POST',
        url: `proxy/get/access/token`
    })({ request_token: uuidV4(4) });

export const updateProfile =
    (profile: UserInfoSchema, cb?: (profile: UserInfoSchema) => void) => async (dispatch) => {
        dispatch({ type: UPDATE_PROFILE });
        const { picture } = await _updateProfile(profile);
        dispatch({ type: asyncActionSuccess(UPDATE_PROFILE), profile, picture });
        if (typeof cb === 'function') cb(profile);
    };

export function updateUserPreferences(preferences: UserPreferencesSchema) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: UPDATE_PREFERENCES });
        try {
            await _updateUserPreferences(preferences);
            dispatch({ type: asyncActionSuccess(UPDATE_PREFERENCES), preferences });
        } catch (e) {
            console.error(e);
        }
    };
}

export const pluginAddConfiguration =
    (pluginConfiguration: PluginConfigurationCreationSchema, cb?: (plugin: any) => void) =>
    async (dispatch) => {
        dispatch({ type: ADD_PLUGIN_CONFIGURATION });
        const plugin = await asyncPluginConfigurationCreate(pluginConfiguration);
        dispatch({ type: asyncActionSuccess(ADD_PLUGIN_CONFIGURATION), plugin });
        if (typeof cb === 'function') cb(plugin);
    };

export const pluginEditConfiguration =
    (
        pluginUuid: string,
        pluginConfiguration: PluginConfigurationSchema,
        cb: (plugin: PluginConfigurationType) => void
    ) =>
    async (dispatch) => {
        dispatch({ type: EDIT_PLUGIN_CONFIGURATION });
        const plugin = await asyncPluginConfigurationUpdate(pluginUuid, pluginConfiguration);
        dispatch({ type: asyncActionSuccess(EDIT_PLUGIN_CONFIGURATION), plugin });
        if (typeof cb === 'function') cb(plugin);
    };

export function pluginDeleteConfiguration(pluginUuid: string) {
    return async (dispatch) => {
        dispatch({ type: DELETE_PLUGIN_CONFIGURATION });
        await asyncPluginConfigurationDelete(pluginUuid);
        dispatch({
            type: asyncActionSuccess(DELETE_PLUGIN_CONFIGURATION),
            pluginUuid
        });
    };
}

export const pluginMergeProperties = (pluginUuid, properties) => ({
    type: PLUGIN_MERGE_PROPERTIES,
    pluginUuid,
    properties
});

export const SET_APP_INFOS = `${USER}/SET_APP_INFOS`;
export function setAppInfos(appInfos: UserAppInfos) {
    return {
        type: SET_APP_INFOS,
        appInfos
    };
}

export const SET_PLUGINS = `${USER}/SET_PLUGINS`;
export function setPlugins(plugins: string[], pluginsEnabled: PluginConfigurationSchema[]) {
    return {
        type: SET_PLUGINS,
        plugins,
        pluginsEnabled
    };
}

export const SET_STORAGE_AND_LICENCES = `${USER}/SET_STORAGE_AND_LICENCES`;
export function setStorageAndLicenses(storage: UserStorage, licenses: UserLicense[]) {
    return {
        type: SET_STORAGE_AND_LICENCES,
        storage,
        licenses
    };
}

export const SET_ACCOUNT = `${USER}/SET_ACCOUNT`;
export function setAccount(account: any) {
    return {
        type: SET_ACCOUNT,
        account
    };
}
