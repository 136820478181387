import React, { Suspense } from 'react';
import {
    AVID_FILE_SUMMARY,
    CAST,
    CAST_FILE,
    CAST_FOLDER,
    CASTS,
    DASHBOARD,
    DIRECTORY,
    FILE,
    FILE_COMPARE,
    FOLDER,
    PDF_FILE_SUMMARY,
    PDF_FOLDER_SUMMARY,
    PLANNING,
    PROJECT,
    PROJECT_CAST,
    PROJECT_FOLDERS,
    PROJECT_SEARCH,
    PROJECTS,
    SEARCH,
    SETTINGS,
    TASKS,
    TRASH
} from '@he-novation/config/paths/herawFrontUris';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';

import { SettingsMode } from '$views/settings/Settings';

const Cast = React.lazy(() => import('$views/cast/Cast/Cast'));
const CastsContainer = React.lazy(() => import('$views/cast/Casts/Casts'));

const CastFile = React.lazy(() => import('$views/cast/CastFile/CastFile'));
const CastFolder = React.lazy(() => import('$views/cast/CastFolder/CastFolderView'));

const Dashboard = React.lazy(
    () => import(/* webpackChunkName: "dashboard" */ '$views/dashboard/Dashboard')
);

const Directory = React.lazy(
    () => import(/* webpackChunkName: "contacts" */ './views/directory/Directory')
);
const File = React.lazy(() => import(/* webpackChunkName: "file" */ './views/file/FileContainer'));
const Folder = React.lazy(() => import(/* webpackChunkName: "folder" */ './views/folder/Folder'));
const Search = React.lazy(() => import(/* webpackChunkName: "search" */ './views/search/Search'));
const Projects = React.lazy(
    () => import(/* webpackChunkName: "projects" */ './views/projects/Projects/Projects')
);

const Planning = React.lazy(
    () => import(/* webpackChunkName: "planning" */ '$views/planning/Planning')
);
const Project = React.lazy(
    () => import(/* webpackChunkName: "project" */ './views/projects/Project/ProjectContainer')
);

const Settings = React.lazy(
    () => import(/* webpackChunkName: "settings" */ './views/settings/Settings')
);
const Summary = React.lazy(
    () => import(/* webpackChunkName: "summary" */ '$views/summary/file/FileSummary')
);
const SummaryAvid = React.lazy(
    () => import(/* webpackChunkName: "summary-avid" */ '$views/summary/file/SummaryAvid')
);

const FolderSummary = React.lazy(
    () => import(/* webpackChunkName: "folder-summary" */ '$views/summary/folder/FolderSummary')
);

const Tasks = React.lazy(() => import(/* webpackChunkName: "tasks" */ '$views/tasks/Tasks'));

const Trash = React.lazy(() => import(/* webpackChunkName: "trash" */ '$views/trash/Trash'));

/* eslint-disable */
export default {
    '/': {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Folder />
            </Suspense>
        )
    },
    [CASTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <CastsContainer />
            </Suspense>
        )
    },
    [CAST]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Cast castUid={params.castUid} castFolderUuid={params.castFolderUuid} />
            </Suspense>
        )
    },
    [DASHBOARD]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Dashboard />
            </Suspense>
        )
    },
    [DIRECTORY]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Directory route={params.route} subroute={params.subroute} />
            </Suspense>
        )
    },
    [FILE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <File shared={false} uuid={params.fileUuid} />
            </Suspense>
        )
    },
    [FILE_COMPARE]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <File shared={false} />
            </Suspense>
        )
    },
    [FOLDER]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Folder uuid={params.folderUuid} />
            </Suspense>
        )
    },
    [PLANNING]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Planning />
            </Suspense>
        )
    },
    [PROJECTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Projects />
            </Suspense>
        )
    },
    [PROJECT]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project
                    uuid={params.uuid}
                    route={params.route}
                    task={queryParams.task}
                    search={queryParams.q}
                />
            </Suspense>
        )
    },
    [PROJECT_CAST]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Project
                    uuid={params.uuid}
                    route="cast"
                    castUid={params.castUid}
                    folderUuid={params.castFolderUuid}
                />
            </Suspense>
        )
    },
    [PROJECT_SEARCH]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project uuid={params.uuid} route="search" search={queryParams.q} />
            </Suspense>
        )
    },
    [PROJECT_FOLDERS]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project
                    uuid={params.uuid}
                    route="folders"
                    folderUuid={params.folderUuid}
                    task={queryParams.task}
                />
            </Suspense>
        )
    },
    [SEARCH]: {
        Component: ({ route: { queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Search
                    folder={
                        queryParams.folderUuid && queryParams.folderName
                            ? {
                                  uuid: queryParams.folderUuid,
                                  name: queryParams.folderName
                              }
                            : undefined
                    }
                    search={queryParams.q}
                />
            </Suspense>
        )
    },
    [SETTINGS]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Settings route={params.route} mode={params.mode as SettingsMode} />
            </Suspense>
        )
    },
    [TASKS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Tasks />
            </Suspense>
        )
    },
    [TRASH]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Trash folderUuid={params.folderUuid} />
            </Suspense>
        )
    }
};

export const castRoutes = {
    [CAST_FOLDER]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastFolder castUid={params.castUid} castFolderUuid={params.castFolderUuid} />
            </Suspense>
        ),
        ignoreParamsInKey: true
    },
    [CAST_FILE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastFile routeParams={params} />
            </Suspense>
        )
    }
};

export const summaryRoutes = {
    [PDF_FILE_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Summary
                    fileUuid={params.fileUuid}
                    fileVersion={params.fileVersion}
                    filters={queryParams?.filters}
                    print={true}
                />
            </Suspense>
        )
    },
    [AVID_FILE_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <SummaryAvid
                    file={{ uuid: params.fileUuid, version: parseInt(params.fileVersion) }}
                    filters={queryParams?.filters}
                    lineBreak={queryParams?.bl}
                />
            </Suspense>
        )
    },
    [PDF_FOLDER_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <FolderSummary folderUuid={params.folderUuid} />
            </Suspense>
        )
    }
};

/* eslint-enable */
