import { Asset } from '@he-novation/config/types/asset.types';
import { FileState } from '@he-novation/config/types/file.types';
import update from 'immutability-helper';
import { SET } from '../../route/routeActions';
import { FETCH_FILE_VIEW, PUBLIC_FILE_VIEW_FETCH } from '../contentActions';
import {
    FILE_SET_ACTIVE_ASSET,
    HIGHLIGHT_VERSION,
    IFRAME_CAPTURE_SET,
    PAUSE,
    PLAY,
    SET_ACTIVE_AUDIO_TRACK,
    SET_ACTIVE_SUBTITLES,
    SET_FINAL,
    SET_PAGE,
    SET_WIDTH_HEIGHT,
    SUBTITLES_FETCH,
    SUBTITLES_GENERATE,
    SUBTITLES_SET_TIMESTAMP,
    SUBTITLES_TOGGLE,
    WS_SUBTITLES_UPDATE
} from './fileActions';

import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    WS_FILE_VERSION_DELETE,
    WS_FILE_VERSION_RESTORE,
    WS_SET_FOLDER_FILE
} from '$redux/content/folder/folderActions';

export const fileInitialState: FileState = {
    breadcrumb: [],
    activeSubtitles: [],
    isLoaded: false,
    comparison: null,
    isPlaying: true,
    subtitlesTimeStamp: new Date().getTime(),
    error: null,
    subtitles: [],
    assets: undefined
};

export default (state: FileState = fileInitialState, action: any = {}): FileState => {
    switch (action.type) {
        case asyncActionSuccess(FETCH_FILE_VIEW):
        case asyncActionSuccess(PUBLIC_FILE_VIEW_FETCH): {
            const playerAssets = action.fileState.assets.filter(
                (a: Asset) => a.type === 'player' && a.version === action.fileState.version
            );

            return update(state, {
                isLoaded: { $set: true },
                error: { $set: action.error },
                $merge: {
                    ...action.fileState,
                    activeAsset:
                        playerAssets.find((a: Asset) => a.quality === 'hd') ||
                        playerAssets.find((a: Asset) => a.quality != 'source') ||
                        playerAssets[0]
                }
            });
        }

        case FILE_SET_ACTIVE_ASSET:
            return update(state, {
                activeAsset: { $set: action.asset }
            });

        case IFRAME_CAPTURE_SET:
            return update(state, {
                iframeCapture: { $set: action.iframeCapture }
            });

        case SET:
            return update(state, {
                $set: {
                    ...fileInitialState,
                    timecodestart: undefined
                }
            });

        case SET_PAGE:
            return update(state, {
                page: { $set: action.page }
            });
        case HIGHLIGHT_VERSION:
            return update(state, {
                highlightedVersion: { $set: action.highlightedVersion }
            });

        case PLAY:
            return update(state, {
                isPlaying: { $set: true }
            });
        case PAUSE:
            return update(state, {
                isPlaying: { $set: false }
            });

        case SET_ACTIVE_AUDIO_TRACK:
            return update(state, {
                activeAudioTrack: { $set: action.activeAudioTrack }
            });

        case SET_ACTIVE_SUBTITLES:
            return update(state, {
                activeSubtitles: { $set: action.activeSubtitles }
            });

        case asyncActionSuccess(SUBTITLES_FETCH):
            return update(state, {
                subtitles: {
                    $set: action.subtitles
                }
            });

        case asyncActionSuccess(SET_FINAL):
            if (state.uuid !== action.uuid) return state;
            return update(state, {
                versionLocked: { $set: action.final }
            });

        case SUBTITLES_SET_TIMESTAMP:
            return update(state, {
                subtitlesTimeStamp: {
                    $set: action.subtitlesTimeStamp
                }
            });

        case SET_WIDTH_HEIGHT:
            return update(state, {
                width: { $set: action.width },
                height: { $set: action.height }
            });

        case SUBTITLES_TOGGLE:
            return update(state, {
                activeSubtitles: {
                    $apply: (activeSubtitles: string[]) =>
                        activeSubtitles.indexOf(action.uuid) > -1
                            ? activeSubtitles.filter((l) => l !== action.uuid)
                            : [...activeSubtitles, action.uuid]
                }
            });

        case asyncActionSuccess(SUBTITLES_GENERATE):
            return update(state, {
                subtitles: {
                    $push: action.subtitles
                }
            });

        case WS_FILE_VERSION_DELETE:
            if (!state.assets) return state;
            return update(state, {
                assets: {
                    $apply: (assets: Asset[]) =>
                        assets.map((a: Asset) =>
                            a.version === action.deletedVersion ? { ...a, expires: new Date() } : a
                        )
                },
                status: {
                    $set: action.status
                }
            });
        case WS_FILE_VERSION_RESTORE:
            if (!state.assets) return state;
            return update(state, {
                assets: {
                    $apply: (assets: Asset[]) =>
                        assets.map((a: Asset) =>
                            a.version === action.restoredVersion ? { ...a, expires: null } : a
                        )
                }
            });
        case WS_SET_FOLDER_FILE:
            return update(state, {
                status: { $set: action.file.status },
                finals: { $set: action.file.finals }
            });

        case WS_SUBTITLES_UPDATE: {
            return update(state, {
                assets: {
                    [state.assets!.findIndex(({ uuid }) => uuid === action.subtitle.assetUuid)]: {
                        $apply: (asset: Asset) => {
                            return { ...asset, updated: new Date() };
                        }
                    }
                }
            });
        }
    }

    return state;
};
