import styles from './ScrollCalendarFilters.module.css';
import React, { useMemo } from 'react';
import cn from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import uniqBy from 'lodash/uniqBy';

import { calendarActiveFiltersAtom, calendarItemsAtom } from '$atoms/calendar.atoms';
import { FilterCheckboxes, FilterCheckboxTag } from '$components/Filters/FilterCheckboxes';
import { useTranslate } from '$hooks/useTranslate';

enum CalendarFilterEnum {
    Teams = 'Teams',
    Labels = 'Labels',
    Authors = 'Authors'
}

type Props = {
    className?: string;
    tag?: FilterCheckboxTag;
};

export const ScrollCalendarFilters = ({ className, tag = FilterCheckboxTag.DropDown }: Props) => {
    const [activeFilters, setActiveFilters] = useAtom(calendarActiveFiltersAtom);
    const items = useAtomValue(calendarItemsAtom);

    const { t } = useTranslate();

    const teams = new Set();
    const labels = new Set();
    const authors = new Set();

    items.forEach((item) => {
        if (item?.teams?.length) {
            item.teams.forEach((team) => teams.add(team));
        }
        if (item?.labels?.length) {
            item.labels.forEach((label) => labels.add(label));
        }
        if (item?.owner?.uuid) {
            authors.add(item.owner);
        }
    });

    const filters = useMemo(
        () => [
            {
                type: CalendarFilterEnum.Teams,
                values: uniqBy(
                    items
                        ?.filter((item) => item?.teams?.length)
                        ?.map((item) => item.teams)
                        ?.flat(),
                    'uid'
                ).map((label) => ({
                    id: label.uuid,
                    label: label.name
                })),
                activeFilters: activeFilters[CalendarFilterEnum.Teams]
            },
            {
                type: CalendarFilterEnum.Labels,
                values: uniqBy(
                    items
                        ?.filter((item) => item?.labels?.length)
                        ?.map((item) => item.labels)
                        ?.flat(),
                    'uid'
                )?.map((label) => ({
                    id: label?.uid,
                    label: label?.name as string
                })),
                activeFilters: activeFilters[CalendarFilterEnum.Labels]
            },
            {
                type: CalendarFilterEnum.Authors,
                values: uniqBy(
                    items?.filter((item) => item?.owner?.uuid)?.flat(),
                    (item) => item.owner.uuid
                ).map((item) => ({
                    id: item.owner.uuid,
                    label: item.owner.email
                })),
                activeFilters: activeFilters[CalendarFilterEnum.Authors]
            }
        ],
        [items, activeFilters]
    );

    const filtersWithValues = filters.filter((filter) => filter.values.length > 0);

    const handleSetFilter = (type: string, _b: any, checkedList: string[]) => {
        setActiveFilters((prevFilters) => ({
            ...prevFilters,
            [type]: checkedList
        }));
    };

    return (
        <div className={cn(styles.filters, className)}>
            {filtersWithValues.map((filter) => (
                <FilterCheckboxes
                    formId="calendar-filters"
                    tag={tag}
                    key={filter.type}
                    className={styles.checkboxDD}
                    label={
                        <label>
                            {t(`calendar.${filter.type}`)}
                            <span>({filter.values.length})</span>
                        </label>
                    }
                    filterName={filter.type}
                    filterValues={filter.values}
                    activeFilters={activeFilters}
                    onSetFilter={handleSetFilter}
                />
            ))}
        </div>
    );
};
