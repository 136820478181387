import styles from './NoteList.module.css';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectTasksLink } from '@he-novation/config/paths/herawFrontUris';
import { CONFIRM } from '@he-novation/config/paths/modals.constants';
import type { Comment, Note as NoteType } from '@he-novation/config/types/note.types';
import { TaskStatus } from '@he-novation/config/types/task.types';
import { ROLE_MANAGER } from '@he-novation/config/utils/acl';
import { noteTypeToI18n } from '@he-novation/config/utils/noteUtils';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { RichText } from '@he-novation/design-system/components/text/RichText/RichText';
import { colorTextFaded } from '@he-novation/design-system/constants/constants.style';
import { asyncAssetFetch } from '@he-novation/front-shared/async/asset.async';
import {
    FORMAT_SHORT_DATETIME_TRANSLATED,
    formatDate,
    remainingDaysToDateShortString
} from '@he-novation/front-shared/utils/datetime';
import { getDisplayName } from '@he-novation/utils/user';
import cn from 'classnames';

import Chips from '$components/base/Chips/Chips';
import Link from '$components/router/Link';
import { FormComment } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormComment/FormComment';
import { TaskStatusSelector } from '$components/TaskStatusSelector/TaskStatusSelector';
import { useModal } from '$hooks/useModal';
import { useCreateNotesAndComments } from '$hooks/useNotes';
import { useTranslate } from '$hooks/useTranslate';
import { setPage } from '$redux/content/file/fileActions';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';
import { updateTask } from '$redux/content/projects/projectsActions';
import { castFileUuidRouteSelector } from '$redux/route/routeSelectors';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

type NoteProps = {
    item: NoteType;
    selected?: string | null;
    replying: string | null;
    setReplying: (replying: string | null) => void;
    creatingNote: boolean;
    editing: string | null;
    setEditing: (editing: string | null) => void;
};

export function Note({
    item,
    selected,
    replying,
    setReplying,
    creatingNote,
    editing,
    setEditing
}: NoteProps) {
    const comments = useMemo(
        () => item.comments.sort((a, b) => a.created.getTime() - b.created.getTime()),
        [item.comments]
    );
    const extraComments = comments.slice(1);
    const lastReply = extraComments[extraComments.length - 1];
    const [displayMore, setDisplayMore] = useState(false);
    const castFileUuid = useSelector(castFileUuidRouteSelector);

    const { t } = useTranslate();
    const dispatch = useDispatch();

    const updateStatus = (status: TaskStatus) => {
        dispatch(updateTask(item.task!.uuid, { status }));
    };

    useEffect(() => {
        if (selected === item.uuid && item.metadata && item.metadata.page !== undefined) {
            dispatch(setPage(item.metadata.page));
        }
    }, [selected, item]);

    return (
        <div
            className={cn(
                styles.note,
                creatingNote && styles.disabled,
                selected === item.uuid ? styles.selected : styles.disabled,
                replying && (replying === item.uuid ? styles.replying : styles.replyingToOther)
            )}
        >
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    {item.team && (
                        <Button
                            className={styles.team}
                            tagName={'div'}
                            tooltip={item.team.name}
                            icon={[
                                {
                                    name: 'users',
                                    stroke: 'none',
                                    fill: item.team.color
                                }
                            ]}
                        />
                    )}

                    <span className={cn(styles.type, selected == item.uuid && styles.selected)}>
                        {item.metadata?.tcIn ? (
                            <>
                                {item.metadata.tcIn}
                                {item.metadata.tcOut && `> ${item.metadata.tcOut}`}
                            </>
                        ) : (
                            <>
                                <span>{t(noteTypeToI18n(item.type))}</span>
                                {item.metadata && item.metadata.page !== undefined && (
                                    <span>
                                        {' '}
                                        - {t('player.Page')} {item.metadata?.page + 1}
                                    </span>
                                )}
                            </>
                        )}
                    </span>
                </div>
                <div className={styles.headerRight}>
                    {!!item.task && !castFileUuid && (
                        <>
                            <TaskStatusSelector
                                status={item.task.status}
                                setTaskStatus={updateStatus}
                                withChevron={true}
                            />
                            <Chips className={styles.link} background={colorTextFaded}>
                                <Link
                                    href={projectTasksLink(item.task.project.uuid, item.task.uuid)}
                                >
                                    #{item.task.number}
                                </Link>
                            </Chips>
                        </>
                    )}

                    {item.comments[0].draft && (
                        <Chips className={styles.draft}>{t('player.Draft')}</Chips>
                    )}
                </div>
            </header>
            <NoteComment
                comment={comments[0]}
                noteUuid={item.uuid}
                editing={editing}
                setEditing={setEditing}
            >
                {item.task?.estimatedEndDate && (
                    <Chips>{remainingDaysToDateShortString(item.task.estimatedEndDate)}</Chips>
                )}
                {item.task?.assignedUsers && item.task.assignedUsers.length > 0 && (
                    <div className={styles.users}>
                        {item.task.assignedUsers.map((user) => (
                            <Chips key={user.uuid}>
                                <Icon icon="at" />
                                {getDisplayName(user)}
                            </Chips>
                        ))}
                    </div>
                )}
            </NoteComment>

            {extraComments.length > 1 && (
                <Button className={styles.more} onClick={() => setDisplayMore(!displayMore)}>
                    {t(displayMore ? 'common.Hide' : 'player.Display {{n}} other comments', {
                        n: extraComments.length - 1
                    })}
                </Button>
            )}
            {extraComments.length > 0 && (
                <div className={styles.comments}>
                    <ul>
                        {!displayMore && lastReply && (
                            <li>
                                <NoteComment
                                    comment={lastReply}
                                    noteUuid={item.uuid}
                                    editing={editing}
                                    setEditing={setEditing}
                                />
                            </li>
                        )}
                        {displayMore &&
                            extraComments.map((comment) => (
                                <li key={comment.uuid}>
                                    <NoteComment
                                        comment={comment}
                                        noteUuid={item.uuid}
                                        editing={editing}
                                        setEditing={setEditing}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
            )}
            {replying !== item.uuid ? (
                <Button
                    className={styles.reply}
                    onClick={() => setReplying(item.uuid)}
                    icon={[
                        {
                            name: 'arrow_reply',
                            stroke: 'white',
                            fill: 'none'
                        }
                    ]}
                >
                    {t('common.Reply')}
                </Button>
            ) : (
                <FormComment noteUuid={item.uuid} cancel={() => setReplying(null)} />
            )}
        </div>
    );
}

export function NoteComment({
    comment,
    children,
    noteUuid,
    editing,
    setEditing
}: {
    children?: ReactNode | ReactNode[];
    comment: Omit<Comment, 'note' | 'file'>;
    noteUuid: string;
    editing?: string | null;
    setEditing: (editing: string | null) => void;
}) {
    const { t } = useTranslate();
    const { openModal, closeModal } = useModal();

    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const { folderRole } = useSelector(folderRoleSelector);
    const { commentDelete } = useCreateNotesAndComments();
    const [hovered, setHovered] = useState(false);

    return (
        <div
            className={styles.comment}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {editing !== comment.uuid ? (
                <>
                    <RichText html={comment.content} />
                    {comment.assets.length > 0 && (
                        <ul className={styles.chips}>
                            {comment.assets.map((asset) => (
                                <li key={asset.uuid}>
                                    <Chips
                                        onClick={async () => {
                                            if (asset.url) return window.open(asset.url);
                                            const a = await asyncAssetFetch(asset.uuid);
                                            window.open(a.url);
                                        }}
                                    >
                                        <Icon icon="paper_clip" stroke="white" />
                                        {asset.name}
                                    </Chips>
                                </li>
                            ))}
                        </ul>
                    )}
                    {comment.tags && comment.tags.length > 0 && (
                        <ul className={styles.chips}>
                            {comment.tags.map((tag) => (
                                <li key={tag}>
                                    <Chips>
                                        <Icon icon="label" stroke="white" />
                                        {tag}
                                    </Chips>
                                </li>
                            ))}
                        </ul>
                    )}
                    {children}
                </>
            ) : (
                <FormComment
                    noteUuid={noteUuid}
                    comment={comment}
                    cancel={() => setEditing(null)}
                />
            )}
            <footer className={styles.footer}>
                <AvatarUser {...comment.user} size={AvatarSize.Small} />{' '}
                {getDisplayName(comment.user)} •{' '}
                {formatDate(comment.created, FORMAT_SHORT_DATETIME_TRANSLATED)}
                {hovered && comment.user.uuid === currentUserUuid && (
                    <Button
                        className={styles.editButton}
                        icon="pencil"
                        onClick={() => setEditing(comment.uuid)}
                    />
                )}
                {hovered &&
                    (comment.user.uuid === currentUserUuid || folderRole === ROLE_MANAGER) && (
                        <Button
                            className={styles.editButton}
                            icon="trash"
                            onClick={() => {
                                openModal(CONFIRM, {
                                    title: t('common.Delete'),
                                    message: t('common.Are you sure you want to delete this?'),
                                    displayLoader: true,
                                    onSubmit: async () => {
                                        await commentDelete(noteUuid, comment.uuid);
                                        closeModal();
                                    }
                                });
                            }}
                        />
                    )}
            </footer>
        </div>
    );
}
