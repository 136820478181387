import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { offset } from '@he-novation/design-system/utils/dom/offset';
import {
    Direction,
    DirectionX,
    DirectionY
} from '@he-novation/design-system/utils/getAbsolutePosition';
import { useAtom } from 'jotai';

import { absoluteMenuAtom } from '$atoms/menus-atoms';

export function useAbsoluteMenu() {
    const [absoluteMenu, setAbsoluteMenu] = useAtom(absoluteMenuAtom);
    return {
        content: absoluteMenu.content,
        position: absoluteMenu.position,
        visible: absoluteMenu.visible,
        direction: absoluteMenu.direction || [DirectionX.Right, DirectionY.Bottom],
        triggerSize: absoluteMenu.triggerSize || [0, 0],
        close() {
            setAbsoluteMenu((aM) => ({
                ...aM,
                content: null,
                visible: false
            }));
        },
        openAbsoluteMenu(
            content: ActionType[] | null,
            position: HTMLElement | [number, number],
            direction?: Direction,
            triggerSize: [number, number] = [0, 0]
        ) {
            let left: string | number;
            let top: string | number;
            if (position instanceof HTMLElement) {
                const r = offset(position);
                left = r.left;
                top = r.top;
            } else {
                [left, top] = position;
            }
            setAbsoluteMenu({
                position: [left, top],
                visible: true,
                content,
                direction,
                triggerSize
            });
        }
    };
}
