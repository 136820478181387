import {
    FrontFolderContentFile,
    FrontFolderContentFileFilter
} from '@he-novation/front-shared/types/file.front-types';
import { atom } from 'jotai';

export type PlaylistAtom = {
    fileUuid: string | null;
    filters: FrontFolderContentFileFilter[];
    folderFiles: FrontFolderContentFile[] | null;
    folderUuid: string | null;
    isOpen: boolean;
    isPlaying: boolean;
};

const initialPlaylistAtom: PlaylistAtom = {
    fileUuid: null,
    filters: [],
    folderFiles: [],
    folderUuid: null,
    isOpen: false,
    isPlaying: false
};

export const playlistAtom = atom<PlaylistAtom>(initialPlaylistAtom);
