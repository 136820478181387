import * as React from 'react';

const PORTAL_ID = '1761731';

export const HubSpotChat = () => {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = `//js.hs-scripts.com/${PORTAL_ID}.js`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};
