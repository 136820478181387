import React, { useEffect } from 'react';
import { MultiActionButtonList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { useOnClickOutside } from '@he-novation/design-system/hooks/useOnClickOutside';
import { getContentAbsolutePosition } from '@he-novation/design-system/utils/getAbsolutePosition';

import { useAbsoluteMenu } from '$hooks/useAbsoluteMenu';

export const ABSOLUTE_MENU_ID = 'absolute-menu';
export function AbsoluteMenu() {
    const {
        content,
        position,
        visible: _visible,
        close,
        direction,
        triggerSize
    } = useAbsoluteMenu();
    const ref = useOnClickOutside<HTMLDivElement>(() => {
        if (content) {
            close();
        }
    }, [content]);

    const [clampedPosition, setClampedPosition] = React.useState<[number, number]>([0, 0]);
    const [visible, setVisible] = React.useState(false);

    useEffect(() => {
        if (_visible) {
            setTimeout(() => {
                const el = document.getElementById(ABSOLUTE_MENU_ID)!;
                const { left, top } = getContentAbsolutePosition(
                    position,
                    el,
                    direction,
                    triggerSize
                );
                setClampedPosition([left, top]);
                setVisible(true);
            }, 0);
        } else {
            setVisible(false);
        }
    }, [position[0], position[1], _visible, direction[0], direction[1]]);

    return (
        <div
            id={ABSOLUTE_MENU_ID}
            ref={ref}
            style={{
                position: 'fixed',
                left: clampedPosition[0],
                top: clampedPosition[1],
                zIndex: 100,
                visibility: content && visible ? 'visible' : 'hidden'
            }}
        >
            {content && (
                <MultiActionButtonList
                    actions={content}
                    onClickableClick={() => {
                        close();
                    }}
                />
            )}
        </div>
    );
}
