import './Editor.scss';
import './quill.scss';
import './quill.snow.scss';
import './quill.snow-dark.scss';
import Quill, { QuillOptions } from 'quill';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Theme } from '../../../enums';
import cn from 'classnames';
import { Loader } from '../../widgets/Loader/Loader';
import { CustomControl, EditorCustomControls } from './EditorCustomControls';
import Delta, { Op } from 'quill-delta';
import Toolbar from 'quill/modules/toolbar';

export { CustomControlType } from './EditorCustomControls';

export type EditorProps = {
    theme?: Theme;
    setQuillRef: Function;
    options?: QuillOptions;
    customControls?: CustomControl[];
    onBlur?: Function;
    onFocus?: Function;
    onChange?: Function;
    html?: string;
    delta?: Delta | Op[];
};

const defaultOptions = {
    modules: {
        toolbar: [
            { header: [1, 2, 3, false] },
            'bold',
            'italic',
            'underline',
            'strike',
            'link',
            {
                color: ['red', 'white', 'black', 'blue']
            },
            {
                background: ['red', 'white', 'black', 'blue']
            }
        ]
    }
};

export const Editor: React.FC<EditorProps> = ({
    html,
    setQuillRef,
    options = {},
    theme = Theme.Dark,
    onBlur,
    onFocus,
    onChange,
    delta,
    customControls
}) => {
    const blurred = useRef<boolean>(true);
    const [loading, setLoading] = useState(true);
    const quillRef = useRef<Quill>();
    const ref = useRef<any>();
    useEffect(() => {
        import('quill').then(({ default: Quill }) => {
            if (ref.current) {
                quillRef.current = new Quill(ref.current, {
                    ...defaultOptions,
                    ...options,
                    theme: 'snow'
                });
                quillRef.current.on('text-change', (delta, oldContents, source) => {
                    if (typeof onChange === 'function') onChange(delta, oldContents, source);
                });
                quillRef.current.on('selection-change', (e) => {
                    const _blurred = !e;
                    if (_blurred !== blurred.current) {
                        if (_blurred && typeof onBlur === 'function') onBlur(e);
                        if (!_blurred && typeof onFocus === 'function') onFocus(e);
                    }
                    blurred.current = _blurred;
                });
                setLoading(false);
                setQuillRef(quillRef.current);
            }
        });
    }, []);

    useEffect(() => {
        if (!loading && (typeof html !== 'undefined' || typeof delta !== 'undefined')) {
            quillRef.current?.setContents(
                delta ? delta : quillRef.current?.clipboard.convert({ html: html })
            );
        }
    }, [html, delta, loading]);

    useEffect(() => {
        if (!loading && quillRef.current) {
            if (options.readOnly) quillRef.current.disable();
            if (!options.readOnly) quillRef.current.enable();
        }
    }, [options]);

    const toolbar = (quillRef.current?.getModule('toolbar') as Toolbar)?.container;

    return (
        <div className={cn('c-editor', theme, options.readOnly && 'is-read-only')}>
            {loading && <Loader theme={theme} />}
            <div className="editor" ref={ref} />
            {customControls &&
                toolbar &&
                ReactDOM.createPortal(
                    <EditorCustomControls theme={theme} controls={customControls} />,
                    toolbar
                )}
        </div>
    );
};

export class EditorWrapper extends React.Component<Omit<EditorProps, 'setQuillRef'>> {
    quill: Quill | null;
    constructor(props: Omit<EditorProps, 'setQuillRef'>) {
        super(props);
        this.quill = null;
    }

    render() {
        return <Editor setQuillRef={(ref: Quill) => (this.quill = ref)} {...this.props} />;
    }
}
