import React from 'react';
import { connect } from 'react-redux';
import { homeLink } from '@he-novation/config/paths/herawFrontUris';
import { PASSWORD_UPDATE_FORM } from '@he-novation/config/paths/modals.constants';
import { ClientPreferences } from '@he-novation/config/types/client.types';
import { Error } from '@he-novation/design-system/components/text/Error/Error';
import { ErrorBoundary } from '@sentry/react';
import { isEqual } from 'lodash/fp';
import debounce from 'lodash/fp/debounce';
import { compose } from 'recompose';
import { Dispatch } from 'redux';

import { ViewError, viewErrorAtom, viewErrorStore } from '$atoms/error-atoms';
import { withHook } from '$components/HOC/withHook';
import { withJotaiAtom } from '$components/HOC/withJotaiAtom';
import combineSelectors from '$helpers/combineSelectors';
import { useModal } from '$hooks/useModal';
import { clientPreferencesSelector } from '$redux/client/clientSelectors';
import { loadQueryParams, set, setFilters } from '$redux/route/routeActions';
import { filtersSelector, routeSelector } from '$redux/route/routeSelectors';
import store from '$redux/store';
import { preferencesSelector, UserInfos, userInfosSelector } from '$redux/user/userSelectors';

const checkPasswordExpiration = debounce(10000, (openModal, clientPreferences, accountInfo) => {
    if (clientPreferences?.password?.expires && clientPreferences?.password?.expires_days) {
        if (!accountInfo.passwordReset) {
            return openModal();
        }

        const passwordExpirationDate = new Date(accountInfo.passwordReset);
        passwordExpirationDate.setTime(
            passwordExpirationDate.getTime() +
                clientPreferences.password.expires_days * 24 * 60 * 60 * 1000
        );
        const now = new Date();
        if (passwordExpirationDate < now) {
            return openModal();
        }
    }
});

type RouterProps = {
    clientPreferences: ClientPreferences;
    configRoute: string;
    filters: Record<string, unknown>;
    loadQueryParams: (routeName: string) => void;
    onUpdate: (previousRoute: string, newRoute: string) => void;
    params: Record<string, string>;
    queryParams: Record<string, string>;
    route: string;
    routes: Record<
        string,
        {
            Component: React.ComponentType<
                {
                    route: {
                        route: string;
                        params: Record<string, string>;
                        queryParams: Record<string, string>;
                    };
                } & unknown
            >;
            ignoreParamsInKey?: boolean;
            preserveQueryParams?: boolean;
        }
    >;
    setFilters: (filters: Record<string, unknown>) => void;
    setRoute: (route: string, silent?: boolean, replace?: boolean) => void;
    setViewError: (error: ViewError) => void;
    userInfos: UserInfos;
    openModal: ReturnType<typeof useModal>['openModal'];
    viewError: ViewError;
    genericErrorMessage: string;
};

class Router extends React.Component<RouterProps> {
    componentDidMount() {
        window.addEventListener('popstate', this.onPopState);
        //facebook redirect
        if (this.props.route.startsWith('_=')) {
            this.props.setRoute(homeLink(), false, true);
        }

        if (window.location.pathname === '/index') {
            const path = '/' + window.location.hash.replace(/^#\/?/, '');
            this.props.setRoute(path, false, true);
        }
    }

    componentDidUpdate(prevProps: RouterProps) {
        if (prevProps.route !== this.props.route) {
            this.props.setViewError(null);
            this.errorBoundary?.resetErrorBoundary();
        }

        if (prevProps.configRoute !== this.props.configRoute) {
            if (this.props.routes[this.props.configRoute]?.preserveQueryParams) {
                this.props.loadQueryParams(this.props.configRoute);
            }

            if (this.props.onUpdate) {
                this.props.onUpdate(prevProps.configRoute, this.props.configRoute);
            }
            checkPasswordExpiration(
                () => this.props.openModal(PASSWORD_UPDATE_FORM, { passwordExpired: true }),
                this.props.clientPreferences,
                this.props.userInfos
            );
        }

        if (!isEqual(prevProps.filters, this.props.filters)) {
            this.props.setFilters(this.props.filters);
        }
    }

    private errorBoundary: ErrorBoundary | null = null;
    render() {
        if (this.props.viewError) {
            return <Error error={this.props.viewError || this.props.genericErrorMessage} />;
        }

        if (this.props.configRoute) {
            const Comp = this.props.routes[this.props.configRoute].Component;
            let key = `${this.props.configRoute}`;
            if (!this.props.routes[this.props.configRoute].ignoreParamsInKey)
                key += `-${JSON.stringify(this.props.params)}`;
            return (
                <ErrorBoundary
                    ref={(r) => (this.errorBoundary = r)}
                    fallback={<Error error={this.props.genericErrorMessage} />}
                >
                    <Comp
                        key={key}
                        route={{
                            route: this.props.route,
                            params: this.props.params,
                            queryParams: this.props.queryParams
                        }}
                    />
                </ErrorBoundary>
            );
        }
        return null;
    }

    onPopState = () => {
        store.dispatch(set(window.location.href.replace(window.location.origin, ''), true));
    };
}

export default compose(
    connect(
        combineSelectors(
            filtersSelector,
            preferencesSelector,
            routeSelector,
            clientPreferencesSelector,
            userInfosSelector
        ),
        (dispatch: Dispatch) => ({
            loadQueryParams: (routeName: string) => dispatch(loadQueryParams(routeName)),
            setFilters: (filters: Record<string, unknown>) => dispatch(setFilters(filters)),
            setRoute: (route, silent, replace) => dispatch(set(route, silent, replace))
        })
    ),
    withHook(useModal, 'openModal'),
    withJotaiAtom(viewErrorAtom, 'viewError', viewErrorStore)
)(Router);
